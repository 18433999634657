import { useState, useEffect } from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import classNames from 'classnames'
import { render } from 'storyblok-rich-text-react-renderer'
import { useSelector, useDispatch } from 'react-redux'
import { useUser } from '@supabase/auth-helpers-react'
import Layout from './Layout'

import { setPopup, setPlaying, setFullscreen } from '@/services/state'
import { createUserFavourite, deleteUserFavourite } from '@/services/utils'

import { StoryblokData } from '@/services/types'

import ImageBlock from './ImageBlock'
import VideoReactPlayer from './VideoReactPlayer'
import SavePractice from '@/components/SavePractice'
import SharePractice from '@/components/SharePractice'
import Tag from './base/Tag'
import Popup from '@/components/Popup'
import MovementItemFull from '@/components/MovementItemFull'
import PracticeRecommended from '@/components/PracticeRecommended'

import { listStyle } from '@/services/utils'

export default function Practice({ blok, id, uuid, slug }: StoryblokData) {
  const [favourites, setFavourties] = useState(null)
  const [isFavourite, setIsFavourite] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showRecommended, setShowRecommended] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const user = useUser()

  const loaded = useSelector((state: any) => state.app.loaded)
  const loading = useSelector((state: any) => state.app.loading)

  const popup = useSelector((state: any) => state.practice.popup)
  const movement = useSelector((state: any) => state.practice.movement)
  const started = useSelector((state: any) => state.practice.started)
  const fullscreen = useSelector((state: any) => state.practice.fullscreen)
  const playing = useSelector((state: any) => state.practice.playing)
  const ended = useSelector((state: any) => state.practice.ended)

  const dispatch = useDispatch()

  const thumbnailImage = `${blok.thumbnail.filename}/m/1600x0/`

  useEffect(() => {
    if (ended) {
      setShowRecommended(true) 
    } else {
      setShowRecommended(false)
    }
  }, [ended])

  useEffect(() => {
    const getFavourites = async () => {
      const response = await fetch(`/api/supabase/favourites/${user?.id}`) 
      const data = await response.json()
      setFavourties(data)
      setIsLoading(false)
    }

    if (user) {
      getFavourites() 
    }
  }, [user])

  useEffect(() => {
    if (!favourites) {
      setIsFavourite(false)
      return 
    }

    const active = Array.from(favourites).findIndex((fav: any) => fav.practice_id === id) > -1

    setIsFavourite(active)
  }, [favourites])

  const handleClick = () => {
    if (!playing) {
      dispatch(setPlaying(true))
    }

    if (!fullscreen) {
      dispatch(setFullscreen(true))
    }
  }

  const handleSave = async () => {
    setIsLoading(true)

    const data = await createUserFavourite(user?.id as string, id, uuid, slug)

    setFavourties(data)

    setIsLoading(false)
  }


  const handleUnsave = async () => {
    setIsLoading(true)

    const data = await deleteUserFavourite(user?.id as string, id)

    setFavourties(data)

    setIsLoading(false)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1200)
  }

  return (
    <Layout>
      <main
        {...storyblokEditable(blok)}
        key={blok._uid}
        className={
          classNames({
            'antialiased min-h-screen pt-16 mobile:pt-28': true,
            'opacity-0': loading,
            'opacity-100': loaded
          })
        }
      >
        <section className="px-1.5 flex mobile:flex-col tablet:flex-col-reverse">
          <div className="w-1/3 px-1.5 flex flex-col mobile:w-full mobile:order-2 mobile:mt-2.5 mobile:px-0 tablet:w-full tablet:mt-2.5">
            <div className="grow flex items-center mobile:px-1">
              <div className="flex items-baseline">
                <h1 className="text-lg leading-title mobile:text-m-md">{blok.title}</h1>
                <p className="text-xs uppercase ml-1 mobile:text-m-sm">{blok.tag.name}</p>
              </div>
            </div>
            <div className="pb-1 desktop:w-3/4 lg:w-4/5 mobile:w-full mobile:text-m-base mobile:mt-4 mobile:hidden">
              <div>{render(blok.description)}</div>
            </div>
          </div>
          <div className="w-2/3 px-1.5 mobile:w-full mobile:order-1 mobile:px-1 tablet:w-full">
            <div className="relative">
              {!blok.video && <ImageBlock image={blok.thumbnail} hero={true} />}
              {blok.video && <VideoReactPlayer key={blok.video.id} url={blok.video.content.videoHLS} poster={thumbnailImage} />}
            </div>
          </div>
        </section>
        <section className="px-1.5 flex flex-wrap justify-end mt-4 relative mobile:justify-start mobile:mt-1 tablet:justify-start tablet:flex-col-reverse tablet:mt-2.5">
          <div className="px-3 absolute right-0 flex mobile:relative mobile:px-1 mobile:mb-10 tablet:relative tablet:w-full tablet:justify-end">
            <SavePractice
              save={handleSave}
              unsave={handleUnsave}
              isSaved={isFavourite}
              isLoading={isLoading}
            />
            <SharePractice
              copy={handleCopy}
              isCopied={isCopied}
            />
          </div>
          <div className="w-2/3 flex items-baseline overflow-hidden mobile:w-full mobile:px-1 tablet:px-3 tablet:mb-4 tablet:w-full">
            <p className="text-md whitespace-nowrap mr-3 mobile:text-m-base">{blok.duration}</p>
              <ul className="flex">
                {blok.style && blok.style.length > 0 &&
                  <li
                    className={listStyle}
                  >
                    {blok.style.map((tag: any, index: number) => (
                      <span key={tag.uuid} className="flex">
                        <Tag key={tag.uuid} uuid={tag.uuid} style="inline" color="grey" />
                        {blok.style.length > 1 && index < blok.style.length - 1 && <span className="mr-1.5 text-grey text-base mobile:mr-1 mobile:text-m-base">,</span>}
                      </span>
                    ))}
                  </li>
                }
                {blok.tone && blok.tone.length > 0 &&
                  <li
                    className={listStyle}
                  >
                    {blok.tone.map((tag: any, index: number) => (
                      <span key={tag.uuid} className="flex">
                        <Tag key={tag.uuid} uuid={tag.uuid} style="inline" color="grey" />
                        {blok.tone.length > 1 && index < blok.tone.length - 1 && <span className="mr-1.5 text-grey text-base mobile:mr-1 mobile:text-m-base">,</span>}
                      </span>
                    ))}
                  </li>
                }
                {blok.body && blok.body.length > 0 &&
                  <li
                    className={listStyle}
                  >
                    {blok.body.map((tag: any, index: number) => (
                      <span key={tag.uuid} className="flex">
                        <Tag key={tag.uuid} uuid={tag.uuid} style="inline" color="grey" />
                        {blok.body.length > 1 && index < blok.body.length - 1 && <span className="mr-1.5 text-grey text-base mobile:mr-1 mobile:text-m-base">,</span>}
                      </span>
                    ))}
                  </li>
                }
                {blok.additionalTags && blok.additionalTags.map((tag: any) => (
                  <li
                    key={tag.uuid}
                    className={listStyle}
                  >
                    <Tag uuid={tag.uuid} style="inline" color="grey" />
                  </li>
                ))}
              </ul>
          </div>
          {blok.equipmentTags && blok.equipmentTags.length > 0 && (
            <div className="w-2/3 flex items-baseline overflow-hidden py-5 mt-5 border-t border-t-grey mobile:px-1 mobile:w-full">
              <p className="text-md whitespace-nowrap mr-3">Equipment</p>
              <ul className="flex">
                {blok.equipmentTags.map((tag: any) => (
                  <li
                    key={tag.uuid}
                    className={listStyle}
                  >
                    <Tag uuid={tag} style="inline" color="grey" />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="mobile:w-full mobile:text-m-base mobile:mt-8 mobile-up:hidden mobile:px-1">
            <div>{render(blok.description)}</div>
          </div>
        </section>
        <section
          className={
            classNames({
              'py-3 px-3 z-30 bottom-0 w-full mt-10': true,
              'fixed bottom-0': true
            })
          }
        >
          <button
            className={
              classNames({
                'w-full pt-1.5 pb-1 text-lg leading-[0.9] uppercase rounded-full bg-slate text-white flex items-start justify-center mobile:text-m-lg mobile:hidden': true,
              })
            }
            onClick={handleClick}
          >
            <span>Practice Now</span>
            {started ? (
                <span className="uppercase text-[10px] inline-block ml-1">{playing ? 'Playing' : 'Paused'}</span>
              ) : (
                <span className="uppercase text-[10px] inline-block ml-1">Play Now</span>
              )
            }
          </button>
        </section>
        {blok.content && blok.content.map((child: any) => (
          <StoryblokComponent
            key={child._uid}
            blok={child}
          />
        ))}

      </main>
      <Popup
        active={popup}
        close={() => dispatch(setPopup(false))}
        style="light"
        width="large"
      >
        {movement && 
          <MovementItemFull
            key={movement.id}
            name={movement.name}
            thumbnail={movement.content.thumbnail}
            video={movement.content.video}
            instructions={movement.content.instructions}
          />
        }
      </Popup>
      <PracticeRecommended
        title={blok.recommendedTitle}
        byline={blok.recommendedByline}
        practices={blok.recommendedItems}
        active={showRecommended}
        handleClose={() => setShowRecommended(false)}
      />
    </Layout>
  )
}
