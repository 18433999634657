import Link from 'next/link'
import classNames from 'classnames'
import { PlayIcon } from '@heroicons/react/24/solid'

import ImageBlock from "@/components/ImageBlock"
import Tag from '@/components/base/Tag'
import LoadedTag from '@/components/base/LoadedTag'

import { listStyle } from '@/services/utils'

export default function PracticeItem({ practice, width, classes }: any) {
  const url = `/practice/${practice.slug}`

  const { style, tone, body } = practice.content

  const renderTag = (tag: any, style: string, color: string) => {
    return typeof tag === 'string' ? (<Tag uuid={tag} style={style} color={color} />) : (<LoadedTag tag={tag} style={style} color={color} />)
  }

  return (
    <li
      className={
        classNames(
          {
            'px-1.5 mobile:w-full mobile:mb-10 mobile:last:mb-0': true,
            'w-1/2': width === '50',
            'w-1/3 tablet:w-1/2 tablet:mb-4': width === '33',
            'w-1/4 tablet:w-1/2 tablet:mb-4': width === '25',
          },
          classes
        )
      }
    >
      <div className="flex items-baseline justify-start mb-1.5">
        <h5 className="text-md mr-1.5 mobile:text-base">
          <Link href={url}>
            {practice.name}
          </Link>
        </h5>
        {practice.content.tag && (
            renderTag(practice.content.tag, 'small', 'slate')
        )}
      </div>
      <Link
        href={url}
        className="block hover:opacity-100 transition-opacity duration-200 relative [&_svg]:hidden [&_svg]:hover:block"
      >
        <ImageBlock image={practice.content.thumbnail} hero={true} />
        <PlayIcon className="w-6 h-6 absolute bottom-3 right-3 text-white rounded-none mobile:w-4 mobile:h-4" />
      </Link>
      <div className="flex items-baseline mt-1">
        <p className="text-md mobile:text-m-base mr-3 whitespace-nowrap">{practice.content.duration}</p>
          <ul className="flex overflow-hidden">
            {style && style.length > 0 &&
              <li
                className={listStyle}
              >
                {style.map((tag: any, index: number) => (
                  <span key={`styleTag${index}`} className="flex">
                    {renderTag(tag, 'inline', 'grey')}
                    {style.length > 1 && index < style.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
                  </span>
                ))}
              </li>
            }
            {tone && tone.length > 0 &&
              <li
                className={listStyle}
              >
                {tone.map((tag: any, index: number) => (
                  <span key={`toneTag${index}`} className="flex">
                    {renderTag(tag, 'inline', 'grey')}
                    {tone.length > 1 && index < tone.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
                  </span>
                ))}
              </li>
            }
            {body && body.length > 0 &&
              <li
                className={listStyle}
              >
                {body.map((tag: any, index: number) => (
                  <span key={`bodyTag${index}`} className="flex">
                    {renderTag(tag, 'inline', 'grey')}
                    {body.length > 1 && index < body.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
                  </span>
                ))}
              </li>
            }
          </ul>
      </div>
    </li>
  )
}
