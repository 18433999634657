import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { setSearchShow } from '../services/state'
import classNames from 'classnames'

const SearchForm = (show: boolean) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const router = useRouter()

  const dispatch = useDispatch()

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault()

    const input = inputRef.current?.value

    console.log(input)

    const url = `/practice/search?for=${input}`

    router.push(url)
  }
  
  useEffect(() => {
    if (show) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }, [show])

  return (
    <div
      className={
        classNames({
          'absolute top-0 left-0 z-30 py-0 bg-offwhite flex justify-end mobile:bottom-0 mobile:top-[unset] mobile:w-full': true,
          'hidden': !show,
        })
      }
    >
      <form
        onSubmit={handleSubmit}
        className={
          classNames({
            'w-[144px] flex relative mobile:min-w-[unset] mobile:w-full': true,
          })
        }
      >
        <input
          ref={inputRef}
          type="text"
          name="search"
          placeholder="Search"
          className="w-full text-base py-0.5 px-2 h-[22px] rounded border border-slate bg-offwhite uppercase"
        />
        <button
          type="submit"
          className="absolute hidden top-0 right-0 text-base py-0.5 px-1 h-[22px] rounded-br rounded-tr uppercase bg-slate text-offwhite"
        >
          Submit
        </button>
      </form>
      <div className="ml-2">
        <button
          className="text-base text-slate py-0.5 px-0.5 rounded absolute right-2 z-50"
          onClick={() => dispatch(setSearchShow(false))}
        >
          <XMarkIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  )
}
export default function SearchButton() {
  const dispatch = useDispatch()

  const show = useSelector((state: any) => {
    return state.search.show
  })

  const handleClick = () => {
    dispatch(setSearchShow(!show))
  }

  return (
    <>
      <button
        className={
          classNames({
            'rounded border border-slate flex justify-start text-slate bg-offwhite px-1 py-0.5 h-[22px] min-w-[144px] mobile:min-w-[unset] mobile:w-full': true,
            '!text-slate !bg-white': show,
          })
        }
        onClick={handleClick}
      >
        <span>
          <MagnifyingGlassIcon className="w-4 h-4" />
        </span>
        <span className="uppercase inline-blok ml-1.5">Search</span>
      </button>
      {SearchForm(show)}
    </>
  )
}
