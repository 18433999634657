type ProfilePaymentProps = {
  item: {
    card_type: string,
    last4: string,
  }
}
export default function ProfilePayment({ item }: ProfilePaymentProps) {
  return (
    <div className="w-full mb-4">
      {item ? (
        <ul className="px-3">
          <li className="border-b border-grey flex py-1 text-base justify-between items-center">
            <p className="capitalize">{item.card_type}</p>
            <p className="">Card ending in {item.last4}</p>
          </li>
        </ul>
      ) : (
        <p className="text-sm uppercase">No card details found</p>
      )}
    </div>
  )
}
