import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react'
import { render } from 'storyblok-rich-text-react-renderer'
import { storyblokEditable, StoryblokComponent } from "@storyblok/react"

import { StoryblokData } from '../services/types'
import Newsletter from './Newletter'

export default function Footer({ blok }: StoryblokData) {
  return (
    <footer
      className="w-full text-base bg-white mt-10"
      {...storyblokEditable(blok)}
    >
      {/* Top Bar */}
      <section className="px-1.5 pt-5 pb-2.5">
        {/* Navigation */}
        <div className="flex w-full justify-between items-start mb-12 mobile:flex-wrap">
          <div className="w-1/5 px-1.5 mobile:w-1/2 mobile:order-2">
            {blok.menu1 && blok.menu1.length > 0 &&
              <ul className="flex flex-col">
                {blok.menu1.map((item: StoryblokData) => (
                  <li
                    key={item._uid}
                    className="uppercase"
                  >
                    <StoryblokComponent blok={item} classes="hover:underline" />
                  </li>
                ))}
              </ul>
            }
          </div>
          <div className="w-1/5 px-1.5 mobile:w-1/2 mobile:order-3">
            {blok.menu2 && blok.menu2.length > 0 &&
              <ul className="flex flex-col mobile:overflow-hidden">
                {blok.menu2.map((item: StoryblokData) => (
                  <li
                    key={item._uid}
                    className="uppercase"
                  >
                    <StoryblokComponent blok={item} classes="hover:underline" />
                  </li>
                ))}
              </ul>
            }
          </div>
          <div className="w-3/5 px-1.5 flex justify-end mobile:w-full mobile:order-1 mobile:mb-12">
            <div className="w-1/2 mobile:w-full">
              <Newsletter
                title={blok.newsletterTitle}
                placeholder={blok.newsletterPlaceholder}
                successMessage={blok.newsletterSuccess}
              />
            </div>
          </div>
        </div>
        
        {/* Legal */}
        <div className="flex w-full justify-end items-center mobile:text-m-base">
          <div className="text-sm">{blok.copyright}</div>
        </div>
      </section>

      {/* Acknowledgement */}
      <div className="border-t border-grey py-6 px-3 text-center mobile:text-m-base">
        {render(blok.acknowledgement)}
      </div>
    </footer>
  )
}
