import { useState } from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Layout from './Layout'

import { StoryblokData } from '@/services/types'

import VideoPlayer from './VideoPlayer'
import VideoReactPlayer from './VideoReactPlayer'

export default function Video({ blok }: StoryblokData) {
  const loaded = useSelector((state: any) => state.app.loaded)
  const loading = useSelector((state: any) => state.app.loading)

  const [fullscreen, setFullscreen] = useState(false)
  return (
    <Layout>
      <main
        {...storyblokEditable(blok)}
        key={blok._uid}
        className={
          classNames({
            'antialiased min-h-screen': true,
            'opacity-0': loading,
            'opacity-100': loaded
          })
        }
      >
        <section className="w-full h-screen flex items-center justify-center">
          {blok.videoHLS &&
            <div className="flex w-1/2 h-[calc(50vw/1.778)]">
              <VideoReactPlayer url={blok.videoHLS} />
            </div>
          }
        </section>
      </main>
    </Layout>
  )
}
