import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import AddAccount from '@/components/forms/AddAccount'

export default function PageForm({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 flex mobile:px-2.5"
      {...storyblokEditable(blok)}
    >
      <div className="w-1/2 px-1.5 flex mx-auto justify-center items-center mobile:w-full mobile:px-0">
        {blok.form === 'account' &&
          <AddAccount />
        }
      </div>
    </section>
  )
}
