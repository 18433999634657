import { FormEvent, useState } from 'react'
import Link from 'next/link'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import classNames from 'classnames'

import Button from '@/components/base/Button'

interface PersonalValues {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
}

const BaseForm = (props: FormikProps<PersonalValues>) => {
  const { touched, errors, isSubmitting } = props
  const errorClasses = 'text-sm uppercase mb-1 text-slate'

  return (
    <Form className="flex flex-col">
      <div className="w-full">
        {touched.firstName && errors.firstName && (<p className={errorClasses}>{errors.firstName}</p>)}
        <Field name="firstName" type="text" placeholder={'First name'} className="form-input w-full mb-2.5 text-base py-1.5 px-5 rounded-[20px] border border-slate bg-offwhite" />
      </div>
      <div className="w-full">
        {touched.lastName && errors.lastName && (<p className={errorClasses}>{errors.lastName}</p>)}
        <Field name="lastName" type="text" placeholder={'Last name'} className="form-input w-full mb-2.5 text-base py-1.5 px-5 rounded-[20px] border border-slate bg-offwhite" />
      </div>
      <div className="w-full">
        {touched.email && errors.email && (<p className={errorClasses}>{errors.email}</p>)}
        <Field name="email" type="email" placeholder={'Email'} className="form-input w-full mb-2.5 text-base py-1.5 px-5 rounded-[20px] border border-slate bg-offwhite" />
      </div>
      <div className="w-full">
        {touched.password && errors.password && (<p className={errorClasses}>{errors.password}</p>)}
        <Field name="password" placeholder="Password" type="password" className="form-input w-full mb-2.5 text-base py-1.5 px-5 rounded-[20px] border border-slate bg-offwhite" />
      </div>
      <div className="w-full flex justify-between items-end">
        <Button
          text="Update details"
          color="dark"
          size="small"
          classes="text-center w-fit"
          style="button"
          disabled={isSubmitting}
        />
      </div>
    </Form>
  )
}

interface PersonalFormProps {
  firstName: string,
  lastName: string,
  email: string,
  password?: string,
  errors?: string,
}

const PersonalDetails = withFormik<PersonalFormProps, PersonalValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    password: props.password ? props.password : '',
  }),
  validationSchema: Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
    password: Yup.string().required('Password is Required'),
  }),
  handleSubmit: async (values, { setSubmitting, setErrors }) => {
    console.log(values)

    setSubmitting(true)
  }
})(BaseForm)

export default function ProfilePersonal(values: PersonalFormProps) {
  const { firstName, lastName, email } = values
  return (
    <div className="">
      <PersonalDetails
        firstName={firstName}
        lastName={lastName}
        email={email}
        password="......"
      />
    </div>
  )
}
