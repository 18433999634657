import { render } from 'storyblok-rich-text-react-renderer'
import { Player, ControlBar, BigPlayButton, ProgressControl, FullscreenToggle, PlayToggle, CurrentTimeDisplay } from 'video-react'
import 'video-react/dist/video-react.css'

import classNames from 'classnames'

export default function MovementItem({ id, name, thumbnail, video, instructions }: any) {
  const image = `${thumbnail.filename}/m/760x0/`

  return (
    <section
      key={id}
      className={
        classNames({
          'w-full first:pl-0 last:pr-0': true,
        })
      }
    >
      <div className="mb-3">
        <h3 className="text-md mobile:text-m-md">{name}</h3>
      </div>
      <div className="w-full">
        <div
          className="w-full h-full relative aspect-w-16 aspect-h-9"
        >
          <div>
          <Player
            fluid={true}
            autoPlay={true}
            playsInline={true}
            poster={image}
            muted={true}
          >
            <source src={video.filename} />
            <BigPlayButton position="center" />
            <ControlBar
              autoHide={false}
              disableDefaultControls={true}
            >
              <PlayToggle />
              <ProgressControl />
              <CurrentTimeDisplay />
              <FullscreenToggle actions={{}} />
            </ControlBar>
          </Player>
        </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="prose text-base max-w-none w-full prose-ol:pl-3 mobile:text-m-base">
          {render(instructions)}
        </div>
      </div>
    </section>
  )
}
