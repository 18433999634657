import Link from 'next/link'
import classNames from 'classnames'

export default function ExploreButton() {

  return (
    <Link
      href="/series"
      className={
        classNames({
          'rounded bg-cream border border-slate text-slate text-center flex justify-center px-1 py-0.5 h-[22px] min-w-[144px] mobile:text-m-base mobile:min-w-[unset]': true,
        })
      }
    >
      <span className="uppercase text-base">Series</span>
    </Link>
  )
}
