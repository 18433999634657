import { useState, useEffect, useContext, useRef } from 'react'
import { useRouter } from 'next/router'
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { useDispatch } from 'react-redux'
import { SWRConfig } from 'swr'
import { setLoaded, setLoading, setScroll, setRoute, setFromRoute, setWidth, setHeight, setVh, setMobile, setTablet, setEnded } from '../services/state'

import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import AppFilter from './AppFilter'

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  const router = useRouter()
  const loadingRef = useRef<any>(null)

  const dispatch = useDispatch()

  // set inital loading
  useEffect(() => {
    if (!loadingRef) {
      return
    }

    loadingRef?.current?.continuousStart()

    setTimeout(() => {
      loadingRef?.current?.complete()

      dispatch(setLoaded(true))
      dispatch(setLoading(false))
      dispatch(setRoute(router.asPath))
    }, 200)
  }, [])

  useEffect(() => {
    if (!window) {
      return
    }

    const vh = window.innerHeight * 0.01

    dispatch(setWidth(window.innerWidth))
    dispatch(setHeight(window.innerHeight))
    dispatch(setMobile(window.innerWidth < 801))
    dispatch(setTablet(window.innerWidth > 800 && window.innerWidth < 1025))
    dispatch(setScroll(window.scrollY)) // set initial scroll position
    
    dispatch(setVh(vh))
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    const handleResize = () => {
      const vh = window.innerHeight * 0.01

      dispatch(setWidth(window.innerWidth))
      dispatch(setHeight(window.innerHeight))
      dispatch(setMobile(window.innerWidth < 768))
      dispatch(setTablet(window.innerWidth > 800 && window.innerWidth < 1025))
      
      dispatch(setVh(vh))
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    const handleScroll = () => {
      dispatch(setScroll(window.scrollY))
    }
    
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [global.window])

  const handleRouteStart = (url: string) => {
    dispatch(setFromRoute(router.asPath))
    dispatch(setLoading(true))
    loadingRef?.current?.continuousStart()
    dispatch(setLoaded(false))
  }

  const handleRouteComplete = (url: string) => {
    dispatch(setLoading(false))
    loadingRef?.current?.complete()
    dispatch(setRoute(url))
    dispatch(setEnded(false)) // set ended to false on every route change
    dispatch(setLoaded(true))
  }

  useEffect(() => {
    if (!router) {
      return
    }

    router.events.on('routeChangeStart', handleRouteStart)
    router.events.on('routeChangeComplete', handleRouteComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteStart)
      router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [router])

  return (
    <>
      <SWRConfig>
        <AppHeader />
        <LoadingBar ref={loadingRef} color={'#505050'} height={1} />
        {children}
        <AppFooter />
        <AppFilter />
      </SWRConfig>
    </>
  )
}
