import { storyblokEditable } from "@storyblok/react"
import { render } from "storyblok-rich-text-react-renderer"
import classNames from "classnames"

import { StoryblokData } from "../services/types"
import ImageBlock from "./ImageBlock"

export default function Hero({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-1.5 py-1 mobile:px-0"
      {...storyblokEditable(blok)}
    >
      <div className="w-full relative">
        {blok.desktop &&
          <ImageBlock
            image={blok.desktop}
            hero={true}
            single={true}
          />
        }
        {blok.mobile && blok.mobile.filename !== '' &&
          <ImageBlock
            image={blok.mobile}
            hero={true}
          />
        }
        {blok.textLeft && blok.textLeft !== '' &&
          <div
            className={
              classNames(
                "text-md absolute left-2.5 px-2.5 top-[50%] transform -translate-y-1/2 mobile:top-2 mobile:translate-y-0 mobile:text-center mobile:w-full mobile:text-base mobile:left-0",
                blok.style === 'light' ? 'text-white' : 'text-slate',
                {
                  'text-base': blok.textSizeLeft === 'small',
                  'text-md': blok.textSizeLeft === 'medium',
                  'text-lg': blok.textSizeLeft === 'large',
                }
              )
            }
          >
            {render(blok.textLeft)}
          </div>
        }
        {blok.textRight && blok.textRight !== '' &&
          <div
            className={
              classNames(
                "text-md absolute right-2.5 px-2.5 top-[50%] transform -translate-y-1/2 mobile:bottom-2 mobile:top-[unset] mobile:text-center mobile:translate-y-0 mobile:w-full mobile:text-base mobile:right-0",
                blok.style === 'light' ? 'text-white' : 'text-slate',
                {
                  'text-base': blok.textSizeLeft === 'small',
                  'text-md': blok.textSizeLeft === 'medium',
                  'text-lg': blok.textSizeLeft === 'large',
                }
              )
            }
          >
            {render(blok.textRight)}
          </div>
        }
      </div>
    </section>
  )
}
