import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit'
import { createWrapper, MakeStore, HYDRATE } from 'next-redux-wrapper'
import { appReducer, filterReducer, searchReducer, practiceReducer, userReducer } from './state'

const combinedReducer = combineReducers({
  app: appReducer,
  filter: filterReducer,
  search: searchReducer,
  practice: practiceReducer,
  user: userReducer
})

const reducer = (state: any, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }
    // persist or perform state here
    if (state.app) nextState.app = state.app
      
    return nextState
  } else {
    return combinedReducer(state, action)
  }
}

export const createStore: MakeStore<any> = () => configureStore({
  reducer,
  devTools: false, // process.env.NODE_ENV !== 'production'
})

export type AppStore = ReturnType<typeof createStore>
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

export const wrapper = createWrapper<AppStore>(createStore, { debug: false })
