import { useLayoutEffect, useRef, ReactNode } from 'react'
import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'

type PopupProps = {
  active: boolean,
  close: () => void,
  hideClose?: boolean,
  canClose?: boolean,
  opacity?: number,
  style: 'light' | 'dark' | 'white',
  width: 'small' | 'medium' | 'large',
  children: ReactNode,
}

// active and close are required props and come from parent component
export default function Popup({ active, close, hideClose = false, canClose, opacity, style, width, children }: PopupProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const keyListener = (evt: KeyboardEvent) => {
      if (active && canClose) {
        if (evt.key === 'Escape' || evt.key === 'Backspace') {
          close()
        }
      }
    }

    window.addEventListener('keydown', keyListener)
    
    return () => {
      window.removeEventListener('keydown', keyListener)
    }
  }, [])
  
  useLayoutEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  const handlePopupClick = (evt: any) => {
    if (active && evt.target === containerRef.current && canClose) {
      close()
    }
  }

  return (
    <aside
      className={
        classNames({
          'bg-[rgba(255,255,255,0.5)] fixed w-full h-full top-0 left-0 z-50 flex items-center p-6 overflow-y-auto mobile:p-2.5': true,
          'hidden': !active,
        })
      }
      style={{ backgroundColor: `rgba(255,255,255,${opacity ?? 0.5})` }}
      ref={containerRef}
      onClick={handlePopupClick}
    >
      <div
        className={
          classNames({
            'w-full !m-auto p-5 relative shadow-md': true,
            'bg-slate text-white': style === 'dark',
            'bg-offwhite text-slate': style === 'light',
            'bg-white text-slate': style === 'white',
            'max-w-2xl': width === 'medium',
            'max-w-[800px]': width === 'large',
            'rounded-xl': true,
          })
        }>
        {!hideClose && (
          <button
            onClick={close}
            className={
              classNames('absolute top-0 right-0 mt-3 mr-3', style === 'light' ? 'text-slate' : 'text-white')
            }
          >
            <XMarkIcon className="w-6 h-6" />
            <span className="sr-only">Close</span>
          </button>
        )}
        {children}
      </div>
    </aside>
  )
}
