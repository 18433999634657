import { useState } from 'react'
import { storyblokEditable } from "@storyblok/react"
import { render } from 'storyblok-rich-text-react-renderer'
import classNames from 'classnames'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

import { StoryblokData } from "../services/types"

export default function FAQs({ blok }: StoryblokData) {
  const [open, setOpen] = useState(false)

  return (
    <li
      key={blok._uid}
      className="w-full border-b border-slate pt-2 pb-1.5 mobile:pt-2.5"
      {...storyblokEditable(blok)}
    >

      <button
        onClick={() => setOpen(!open)}
        className={
          classNames({
            'appearance-none w-full text-left flex justify-between': true,
          })
        }
      >
        <span className="text-base mobile:text-m-base">{blok.question}</span>

        {open
          ? <ChevronUpIcon className="w-5 h-5 inline-block ml-2" />
          : <ChevronDownIcon className="w-5 h-5 inline-block ml-2" />
        }
      </button>
      <section
        className={
          classNames({
            'w-full my-2.5 prose text-base mobile:text-m-base max-w-none pr-6': true,
            'hidden': !open,
          })
        }
      >
        {render(blok.answer)}
      </section>
    </li>
  )
}
