import { StoryblokComponent, storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

export default function FAQs({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-2.5 py-1 mt-4 mb-12 mx-auto max-w-[900px] mobile:mb-10"
      {...storyblokEditable(blok)}
    >
    <div className="w-full border-b border-black py-1.5 text-base mobile:text-m-sm">
      <h5 className="uppercase">{blok.title}</h5>
    </div>
    {blok.items &&
      <ul className="">
        {blok.items.map((item: StoryblokData) => (
          <StoryblokComponent
            blok={item}
            key={item._uid}
          />
        ))}
      </ul>
    }
    </section>
  )
}
