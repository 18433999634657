import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSupabaseClient } from '@supabase/auth-helpers-react'

import classNames from 'classnames'

import SignInOTP from '@/components/forms/SignInOTP'
import SignInOTPToken from '@/components/forms/SignInOTPToken'
import Button from '@/components/base/Button'
import Popup from '@/components/Popup'

const SignInStep = (props: any) => {
  const { title, step, loaded, children } = props

  return (
    <div className={classNames('flex flex-col w-full opacity-0 transition-opacity duration-300 delay-200 relative', {
      'hidden': step !== props.step,
      'opacity-100': loaded
    })}>
      <header className="my-2.5 relative">
        <h5 className="text-base text-center">{title}</h5>
      </header>

      {children}
    </div>
  )
}

export default function SignIn() {
  const [step, setStep] = useState(1)
  const [serverErrors, setServerErrors] = useState<any>([])

  const [signinLoading, setSigninLoading] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)
  const [successLoading, setSuccessLoading] = useState(false)

  const [email, setEmail] = useState<string | null>(null)
  const [emailVerified, setEmailVerified] = useState(false)
  const [tokenVerified, setTokenVerified] = useState(false)

  const [signedIn, setSignedIn] = useState(false)
  const [hasSubscription, setHasSubscription] = useState<boolean | null>(null)

  const supabase = useSupabaseClient()
  const router = useRouter()

  const handleClose = async () => {
    await handleAutoSignOut()

    // restore scroll
    document.body.style.overflow = 'auto'
    
    router.push('/')
  }

  const handleAutoSignOut = async () => {
    const { error } = await supabase.auth.signOut()

    if (error) {
      console.log(error)
    }

    setSignedIn(false)
    setHasSubscription(false)
  }

  const handleSignInOTP = async (values: { otp: string }) => {
    // handle early return if email is null
    if (!email) {
      setServerErrors([ 'Email is required' ])
      return
    }

    const { data: { session }, error } = await supabase.auth.verifyOtp({
      email,
      token: values.otp,
      type: 'email',
    })

    if (session) {
      setStep(3)
      setSuccessLoading(true)
    }

    if (error) {
      setServerErrors([ error.message ])

      setTimeout(() => {
        setServerErrors([])
      }, 12000)

      return 
    }
  }

  const handleSignInEmail = async (values: { email: string }) => {
    // set email for OTP verification used later
    setEmail(values.email)

    const { data, error } = await supabase.auth.signInWithOtp({
      email: values.email,
      options: {
        // set this to false if you do not want the user to be automatically signed up
        shouldCreateUser: false,
      },
    })

    // TODO: check that data is null for user and session then continue to next step
    setStep(2)
    setOtpLoading(true) 

    if (error) {
      setServerErrors([ error.message ])

      setTimeout(() => {
        setServerErrors([])
      }, 12000)

      return 
    }
  }

  useEffect(() => {
    setSigninLoading(true)
  }, [])

  return (
    <>
      <div className="w-[66%] max-w-lg mobile:w-full mobile:max-w-none tablet:w-full tablet:max-w-none">
        {step === 1 && (
          <SignInStep title="Sign In" step={1} loaded={signinLoading}>
            <SignInOTP
              userAction={handleSignInEmail}
              serverErrors={serverErrors}
            />
          </SignInStep>
        )}
        {step === 2 && (
          <SignInStep title="We have sent you a verification code to login" step={2} loaded={otpLoading}>
            <div className="w-full absolute text-center text-[36px] top-[-60px] mobile:text-m-lg">
              <h3>Please check your emails</h3>
            </div>
            <SignInOTPToken
              userAction={handleSignInOTP}
              serverErrors={serverErrors}
            />
          </SignInStep>
        )}
        {step === 3 && (
          <SignInStep title="Success" step={3} loaded={successLoading}>
            <p className="text-center text-sm text-grey uppercase">You have successfully logged in</p>
          </SignInStep>
        )}
      </div>
      <Popup
        active={hasSubscription === false}
        close={handleClose}
        hideClose={false}
        canClose={true}
        opacity={0.8}
        style="dark"
        width="medium"
      >
        <aside className="p-12 flex flex-col">
          <header className="mobile:mb-6 text-center"> 
            <h2 className="text-base uppercase mb-5 mobile:text-m-base">It looks like your membership is no longer active</h2>
            <p className="text-base">
              We would love to see you back.<br/>
              Please login to our customer portal and resubscribe to begin practicing with us again.
            </p>
          </header>
          <div className="w-full flex justify-center mt-8">
            {false && (
              <Button
                text="Resubscribe"
                color="light"
                size="large"
                link={true}
                linkUrl={''}
                externalLink={true}
                classes="!w-full text-center border-white"
              />
            )}
            {!true && (
              <Button
                text="Resubscribe"
                color="light"
                size="large"
                link={true}
                linkUrl="/account/create"
                externalLink={true}
                classes="!w-full text-center border-white"
              />
            )}
          </div>
        </aside>
      </Popup>
    </>
  )
}
