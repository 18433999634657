import { useState } from 'react'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { CardComponent, CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

import { errorClasses } from '@/services/utils'

import Button from '@/components/base/Button'

const options = {
  fonts: [
    { fontFamily: 'neue-haas', src: "url('https://rise-platform.vercel.app/fonts/NeueHaasDisplay-Roman.woff') format('woff')" },
  ],
  styles: {
    base: {
      fontSize: '14px',
      fontFamily: 'neue-haas, helvetica, arial, sans-serif',
      fontWeight: '300',
      lineHeight: '1.2em',
      color: '#505050',
      '::placeholder': {
        color: '#6b7280',
      }
    },
    empty: {
      '::placeholder': {
        color: '#6b7280',
      }
    }
  }
}

interface PaymentValues {
  cardNumber: number,
  cardExpiry: number,
  cardCvv: number,
  coupon?: string | null,
  consent: boolean,
  newsletter: boolean,
}

type OtherProps = {
  userAction: any,
  cardRef: any,
  serverErrors?: any,
}

const BaseForm = (props: OtherProps & FormikProps<PaymentValues>) => {
  const [validCoupon, setValidCoupon] = useState<boolean | null>(null)
  const [couponTimeout, setCouponTimeout] = useState<any>(null)
  const { touched, errors, isSubmitting, serverErrors } = props

  const fieldClasses = 'form-input text-base w-full mb-2.5 pt-2 pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'

  const handleCardChange = (event: any) => {
    if (event.field === 'number') {
      if (event.complete && event.error === undefined) {
        props.setFieldValue('cardNumber', 1)
      } else {
        props.setFieldValue('cardNumber', 0)
      }
    }
    if (event.field === 'expiry') {
      if (event.complete && event.error === undefined) {
        props.setFieldValue('cardExpiry', 1)
      } else {
        props.setFieldValue('cardExpiry', 0)
      }
    }
    if (event.field === 'cvv') {
      if (event.complete && event.error === undefined) {
        props.setFieldValue('cardCvv', 1)
      } else {
        props.setFieldValue('cardCvv', 0)
      }
    }
  }

  const checkCoupon = async (evt: any) => {
    evt.preventDefault()

    if (props.values.coupon === undefined || props.values.coupon === null || props.values.coupon.length < 1) {
      return
    }
    
    // @ts-ignore
    const data = await props.handleCoupon(props.values.coupon)
    
    if (!data.success && data.coupon?.status !== 'active') {
      setValidCoupon(false)

      if (couponTimeout) {
        clearTimeout(couponTimeout)
        
        setCouponTimeout(setTimeout(() => {
          setValidCoupon(null)
          props.values.coupon = null
        }, 4000))
      } else {
        setCouponTimeout(setTimeout(() => {
          setValidCoupon(null)
          props.values.coupon = null
        }, 4000))
      }
    }

    if (data.coupon && data.coupon.status === 'active') {
      setValidCoupon(true)
    }
  }

  return (
    <Form
      className={
        classNames({
          'flex flex-col': true,
          'opacity-80': isSubmitting,
        })
      }
    >
      <CardComponent
        ref={props.cardRef}
        fonts={options.fonts}
        styles={options.styles}
        onChange={handleCardChange}
      >
        <div className="w-full">
          {touched.cardNumber && errors.cardNumber && (<p className={errorClasses}>{errors.cardNumber}</p>)}
          <Field name="cardNumber" component={CardNumber} className={fieldClasses} />
        </div>
        <div className="w-full flex">
          <div className="w-1/2 mr-2.5">
            {touched.cardExpiry && errors.cardExpiry && (<p className={errorClasses}>{errors.cardExpiry}</p>)}
            <Field name="cardExpiry" component={CardExpiry} className={fieldClasses} />
          </div>
          <div className="w-1/2">
            {touched.cardCvv && errors.cardCvv && (<p className={errorClasses}>{errors.cardCvv}</p>)}
            <Field name="cardCvv" component={CardCVV} className={fieldClasses} />
          </div>
        </div>
      </CardComponent>

      <div className="flex flex-col w-full mt-4">
        <div className="flex items-end mb-2.5 relative mobile-up:mx-1">
          {validCoupon !== null && !validCoupon && 
            <p className="absolute bottom-[-20px] text-sm text-[red] tracking-wide">Invalid coupon code</p> 
          }
          {validCoupon !== null && validCoupon && 
            <p className="absolute bottom-[-20px] text-sm  text-[green] tracking-wide">Successfully added coupon code</p> 
          }
          <div className="w-1/2 mr-2.5 tablet:w-full">
            <Field type="text" name="coupon" placeholder="Enter promo code" className="w-full mr-2.5 text-base focus:outline-0 focus:ring-0 px-0 py-1 bg-[transparent] border-0 !border-b border-grey" />
          </div>
          <div className="w-1/2 relative">
            <Button
              text="Apply Promo code"
              size="small"
              classes="text-center w-fit px-12 min-w-[160px] w-full mobile:w-full whitespace-nowrap grow tablet:w-full"
              color="dark"
              clickEvent={checkCoupon}
              disabled={validCoupon === null || validCoupon === false ? false : true}
            />
            {typeof validCoupon === typeof Boolean && validCoupon && (
              <CheckIcon className="w-4 h-4 ml-2 absolute text-[green] right-[-10px] top-1.5" />
            )} 
            {typeof validCoupon === typeof Boolean && !validCoupon && (
              <XMarkIcon className="w-4 h-4 ml-2 text-[red] absolute right-[-10px] top-1.5" />
            )}
          </div>
        </div>
      </div>
      <div className="w-full mt-10 mb-2 mobile-up:ml-2">
        {touched.consent && errors.consent && (<p className={errorClasses + ' !mb-3 !ml-5'}>{errors.consent}</p>)}
        <label className="flex">
          <Field type="checkbox" name="consent" />
          <span className="ml-2.5 text-base mobile:text-m-base">I agree to the <a href="/terms-of-use" className="underline" target="_blank" rel="noopener noreferrer">Terms</a>, <a href="/privacy-policy" className="underline" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and end user license agreement</span>
        </label>
      </div>

      <div className="w-full mb-5 mobile-up:ml-2">
        <label className="flex">
          <Field type="checkbox" name="newsletter" />
          <span className="ml-2.5 text-base mobile:text-m-base">Join our newsletter for fortnightly notes</span>
        </label>
      </div>
      <div className="w-full flex justify-between items-end mobile:flex-col mobile:justify-start mobile:items-start tablet:mt-5">
        <Button
          text="Create Account"
          size="small"
          classes="text-center w-fit px-12 min-w-[160px] mobile:w-full tablet:w-full tablet:pt-2 tablet:pb-2"
          color="dark"
          disabled={isSubmitting}
        />
      </div>
    </Form>
  )
}

interface EmailFormProps {
  errors?: string,
  handleSubmit: any,
  handleCoupon: any,
  cardRef: any,
  serverErrors?: any,
}

const PaymentForm = withFormik<EmailFormProps, PaymentValues>({
  mapPropsToValues: () => ({
    cardNumber: 0,
    cardExpiry: 0,
    cardCvv: 0,
    consent: false,
    newsletter: false,
  }),
  validationSchema: Yup.object({
    cardNumber: Yup.number().required('Required').min(1, 'Valid card required'),
    cardExpiry: Yup.number().required('Required').min(1, 'Valid expiry required'),
    cardCvv: Yup.number().required('Required').min(1, 'Valid CVV required'),
    consent: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)

    props.cardRef.current.tokenize().then((payload: any) => {
      props.handleSubmit({ ...values, ...payload })
    })
  }
})(BaseForm)

export default function ProfilePayment(props: any) {
  return (
    <>
      <PaymentForm
        handleSubmit={props.userAction.payment}
        handleCoupon={props.userAction.coupon}
        cardRef={props.cardRef}
      />
    </>
  )
}
