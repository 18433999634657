import { useEffect, useRef } from 'react'
import { storyblokEditable } from "@storyblok/react"
import { render } from "storyblok-rich-text-react-renderer"
import { useSelector } from 'react-redux'
import classNames from "classnames"

import { StoryblokData } from "../services/types"

export default function HeroVideo({ blok }: StoryblokData) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const mobileVideoRef = useRef<HTMLVideoElement>(null)

  const mobile = useSelector((state: any) => state.app.mobile)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playsInline = true
      videoRef.current.play()
    }
  }, [videoRef])

  useEffect(() => {
    if (!mobileVideoRef.current) {
      return
    }

    setTimeout(() => {
      if (mobileVideoRef.current)
        mobileVideoRef.current.playsInline = true
        mobileVideoRef.current?.play()
    }, 400)
  }, [mobileVideoRef])

  const playVideo = (evt: any) => {
    console.log('play V', evt)
    if (videoRef.current) {
      videoRef.current.play()
    }
  }

  const playMobileVideo = (evt: any) => {
    console.log('play MV', evt)
    if (mobileVideoRef.current) {
      mobileVideoRef.current.play()
    }
  }

  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1"
      {...storyblokEditable(blok)}
    >
      <div className="w-full relative">
        {blok.video && (!mobile || blok.mobileVideo.filename === '') && (
          <div
            className={
             classNames({
               'relative w-full mobile:!h-[unset] mobile:aspect-w-16 mobile:aspect-h-9 mobile:my-3': true,
               'aspect-w-16 aspect-h-9': !blok.containHeight,
               'h-[100vh]': blok.containHeight && blok.height === '100',
               'h-[80vh]': blok.containHeight && blok.height === '80',
               'h-[75vh]': blok.containHeight && blok.height === '75',
               'h-[66vh]': blok.containHeight && blok.height === '66',
               'h-[50vh]': blok.containHeight && blok.height === '50',
               'h-[40vh]': blok.containHeight && blok.height === '40',
               'h-[33vh]': blok.containHeight && blok.height === '33',
             })
            }
          >
            <video
              className="w-full absolute top-0 left-0 h-full"
              autoPlay
              muted
              loop
              playsInline
              ref={videoRef}
              onClick={playVideo}
            >
              <source src={blok.video.filename} type="video/mp4" />
            </video>
          </div>
        )}
        {blok.mobileVideo && (blok.mobileVideo.filename !== '' && mobile) && (
          <div
            className={
             classNames({
               'relative w-full': true,
               'aspect-w-9 aspect-h-16': !blok.containHeight,
             })
            }
          >
            <video
              className="w-full absolute top-0 left-0 h-full"
              autoPlay
              muted
              loop
              playsInline
              ref={mobileVideoRef}
              onClick={playMobileVideo}
            >
              <source src={blok.mobileVideo.filename} type="video/mp4" />
            </video>
          </div>
        )}
        {blok.textLeft && blok.textLeft !== '' &&
          <div className={classNames("text-md absolute left-2.5 px-2.5 top-[50%] transform -translate-y-1/2 mobile:top-2 mobile:translate-y-0 mobile:text-center mobile:w-full mobile:text-base mobile:left-0 pointer-events-none", blok.style === 'light' ? 'text-white' : 'text-slate')}>
            {render(blok.textLeft)}
          </div>
        }
        {blok.textRight && blok.textRight !== '' &&
          <div className={classNames("text-md absolute right-2.5 px-2.5 top-[50%] transform -translate-y-1/2 mobile:bottom-2 mobile:top-[unset] mobile:text-center mobile:translate-y-0 mobile:w-full mobile:text-base mobile:right-0 pointer-events-none", blok.style === 'light' ? 'text-white' : 'text-slate')}>
            {render(blok.textRight)}
          </div>
        }
      </div>
    </section>
  )
}
