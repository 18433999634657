import classNames from 'classnames'
import { useTag } from '../../services/utils'

const Tag = ({ uuid, style, color }: any) => {
  const { tag, isLoading, isError } = useTag(uuid)

  if (isError) {
    return (<p className="hidden">Not found</p>)
  }

  return (
    <p
      key={uuid}
      className={
        classNames({
          'transition-all duration-300 ease-in-out whitespace-nowrap': true,
          'text-base mobile:text-m-base': style === 'inline' || style === undefined,
          'text-xs uppercase': style === 'small',
          'text-grey': color === 'grey',
          'text-white mobile:text-grey': color === 'white',
          'blur-[4px]': isLoading,
          'hidden': !tag?.story?.name,
        })
      }
    >
      {!isError && 
        isLoading && tag === null ? 'Loading' : tag?.story?.name
      }
    </p>
  )
}

export default Tag
