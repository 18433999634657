type ProfileSubscriptionProps = {
  item: {
    id: string,
    status: string,
    current_term_end: number,
    trial_end: number,
    next_billing_at: number,
    coupon: string,
    subscription_items: any[],
  }
}
export default function ProfileSubscription({ item }: ProfileSubscriptionProps) {
  console.log(item)

  const renderPromo = () => {
    if (!item?.coupon) return
    
    return ` (Coupon: ${item.coupon})`
  }

  const renderTitle = () => {
    const subscription = item?.subscription_items[0]

    switch (subscription.item_price_id) {
      case 'Standard-AUD-Monthly':
        return 'Monthly'
      case 'Standard-AUD-Quarterly':
        return 'Quarterly'
      case 'Standard-AUD-Yearly':
        return 'Yearly'
      default:
        return ''
    }
  }
  return (
    <div className="w-full mb-4">
      {item && item?.status ? (
        <ul className="px-3">
          <li
            key={item.id}
            className="border-b border-grey flex py-1 text-base justify-between items-center"
          >
            {item.status === 'non_renewing' && (
              <>
              <p>{renderTitle()}, Cancelled</p>
              <p>Subscription finishes{item.current_term_end ? new Intl.DateTimeFormat("en-AU", {}).format(item.current_term_end * 1000) : ''}</p>
              </> 
            )}
            {item.status === 'active' && (
              <>
              <p>{renderTitle()} {renderPromo()}</p>
              <p>Next billing date {item.current_term_end ? new Intl.DateTimeFormat("en-AU", {}).format(item.current_term_end * 1000) : ''}</p>
              </> 
            )}
            {item.status === 'in_trial' && (
              <>
              <p>{renderTitle()}, Trial</p>
              <p>Subscription starts {item.trial_end ? new Intl.DateTimeFormat("en-AU", {}).format(item.trial_end * 1000) : ''}</p>
              </> 
            )}
            {item.status === 'future' && (
              <>
              <p>{renderTitle()}, Future</p>
              <p>Subscription starts {item.next_billing_at ? new Intl.DateTimeFormat("en-AU", {}).format(item.next_billing_at * 1000) : ''}</p>
              </> 
            )}
          </li>
        </ul>
      ) : (
        <p className="text-sm uppercase">No subscriptions found</p>
      )}
    </div>
  )
}
