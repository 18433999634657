import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import Button from './base/Button'

export default function TagsRow({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-20 mobile:my-12"
      {...storyblokEditable(blok)}
    >
      <ul className="flex flex-wrap items-center justify-center desktop:w-4/5 desktop:mx-auto">
        {blok.tags && blok.tags.map((tag: any) => (
          <li
            key={tag.id}
            className="mx-1.5 my-2.5 mobile:m-1 tablet:my-2"
          >
            <Button
              text={tag.name}
              color="light"
              size="xl"
              link={true}
              linkUrl={`/practice/filters?tags=${tag.slug}`}
              classes="text-lg px-20 pt-3.5 pb-3 !rounded-full mobile:text-m-base mobile:px-8 mobile:pt-1.5 mobile:pb-1 tablet:text-md tablet:px-10 tablet:pb-2 tablet:pt-2"
            />
          </li>
        ))}
      </ul>
    </section>
  )
}
