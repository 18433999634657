import { useState, useEffect } from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react'
import classNames from 'classnames'
import { render } from 'storyblok-rich-text-react-renderer'
import { useSelector, useDispatch } from 'react-redux'
import Layout from './Layout'

import { setPopup } from '@/services/state'

import { StoryblokData } from '@/services/types'

import Popup from '@/components/Popup'
import MovementItemFull from '@/components/MovementItemFull'

export default function Movement({ blok, name }: StoryblokData) {
  const loaded = useSelector((state: any) => state.app.loaded)
  const loading = useSelector((state: any) => state.app.loading)

  const popup = useSelector((state: any) => state.practice.popup)
  const movement = useSelector((state: any) => state.practice.movement)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPopup(true))
  })

  return (
    <Layout>
      <main
        {...storyblokEditable(blok)}
        key={blok._uid}
        className={
          classNames({
            'antialiased min-h-screen pt-16': true,
            'opacity-0': loading,
            'opacity-100': loaded
          })
        }
      >
        <Popup
          active={popup}
          close={() => dispatch(setPopup(false))}
          style="light"
          width="large"
        >
          {blok && 
            <MovementItemFull
              key={blok._uid}
              name={name}
              thumbnail={blok.thumbnail}
              video={blok.video}
              instructions={blok.instructions}
            />
          }
        </Popup>
      </main>
    </Layout>
  )
}
