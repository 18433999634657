import { useDispatch, useSelector } from 'react-redux'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { setFilterResults, setFilters, setFilterShow } from '../services/state'
import { useRouter } from 'next/router'
import classNames from 'classnames'

export default function FilterButton() {
  const dispatch = useDispatch()

  const router = useRouter()

  const show = useSelector((state: any) => {
    return state.filter.show
  })

  const handleClick = () => {
    dispatch(setFilters([]))
    dispatch(setFilterResults([]))
    dispatch(setFilterShow(false))

    router.push('/practice/all')
  }

  return (
    <button
      className={
        classNames({
          'rounded bg-offwhite border border-slate flex justify-between text-slate px-1 py-0.5 h-[22px] min-w-[144px] mobile:min-w-[unset] mobile:w-full': true,
          // '!text-slate !bg-white': show,
        })
      }
      onClick={handleClick}
    >
      <span className="uppercase text-base">Clear Filter</span>
      <span>
        <XMarkIcon className="w-4 h-4" />
      </span>
    </button>
  )
}
