import Image from 'next/image'

import { storyblokEditable } from "@storyblok/react"
import { render } from "storyblok-rich-text-react-renderer"

import { StoryblokData } from "@/services/types"

import ImageBlock from "@/components/ImageBlock"
import Button from "@/components/base/Button"

export default function HeroForm({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-1.5 py-1 flex mobile:px-2.5 mobile:min-h-[66vh] tablet:flex-col-reverse tablet:items-center tablet:mt-16"
      {...storyblokEditable(blok)}
    >
      <div className="w-1/2 px-1.5 relative max-h-[calc(100vh-80px)] overflow-hidden mobile:hidden tablet:w-full tablet:mt-16">
        {blok.hero &&
          <ImageBlock image={blok.hero} />
        }
        <h1 className="text-base text-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] uppercase z-1">{blok.title}</h1>
        <div className="absolute bottom-2.5 left-4 md:w-2/3 w-full 2xl:w-1/2">
          {blok.byline && <p className="text-white text-lg">{blok.byline}</p>}
        </div>
      </div>
      <div className="w-1/2 px-1.5 flex flex-col justify-center items-center mobile:w-full mobile:min-h-full mobile:px-0 tablet:w-full">
        <div className="w-full grow flex flex-col items-center justify-center text-center">
          {blok.qrImage &&
            <Image
              src={blok.qrImage.filename}
              alt={blok.qrImage.alt}
              width={240}
              height={240}
            />
          }

          <div className="w-full flex flex-col items-center justify-center mt-4">
            <p>{blok.qrTitle}</p>
            <div className="w-full flex justify-center">
              <Button
                text={blok.qrButton}
                color="dark"
                size="small"
                link={true}
                linkUrl={blok.qrLink.url}
                externalLink={true}
                classes="!min-w-[50%] max-w-[430px] text-center mt-2"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center">
          {blok.legalText &&
            <div className="w-full prose text-base text-center flex flex-col items-center justify-center">
              {render(blok.legalText)}
            </div>
          }          
          {blok.termsText &&
            <div className="w-full prose text-base text-center flex flex-col items-center justify-center mt-3">
              {render(blok.termsText)}
            </div>
          }          
        </div>
      </div>
    </section>
  )
}
