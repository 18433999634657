import { useState, useEffect } from 'react'
import { storyblokEditable, ISbStoriesParams, getStoryblokApi } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import BlogItem from './BlogItem'

export default function BlogArticles({ blok }: StoryblokData) {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    const fetchArticles = async () => {
      const sbParams: ISbStoriesParams = {
        version: 'published',
        starts_with: 'contemplation/',
        content_type: 'article',
        per_page: 100,
        resolve_relations: [
          'article.topics',
        ]
      }

      const { data } = await getStoryblokApi().get(`cdn/stories`, sbParams)

      setArticles(data.stories)
    }

    fetchArticles()
  }, [])

  return (
    <section
      key={blok._uid}
      className="w-full px-3 my-4 flex justify-between"
      {...storyblokEditable(blok)}
    >
      {articles && articles.length > 0 && 
        <ul className="w-full flex flex-wrap items-center justify-start">
          {articles.map((article: any) => (
            <BlogItem
              key={article.id}
              content={article.content}
              row={false}
              slug={article.slug}
            />
          ))}
        </ul>
      }
    </section>
  )
}
