import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  loaded: boolean,
  loading: boolean,
  width: number,
  height: number,
  mobile: boolean,
  tablet: boolean,
  vh: number,
  scroll: number | null,
  route: string | null,
  fromRoute: string | null,
}

export interface filterState {
  show: boolean,
  filters: [],
  results: [],
}

export interface searchState {
  show: boolean,
  search: string,
  results: [],
}

export interface practiceState {
  popup: boolean,
  movement: object | null,
  started: boolean,
  ended: boolean,
  playing: boolean,
  fullscreen: boolean,
  duration: number,
  currentTime: number,
  previous: string,
}

export interface userState {
  user: object | null,
  session: object | null,
}

const initialState: AppState = {
  loaded: false,
  loading: true,
  width: 0,
  height: 0,
  mobile: false,
  tablet: false,
  vh: 8,
  scroll: null,
  route: null,
  fromRoute: null,
}

const filterInitialState: filterState = {
  show: false,
  filters: [],
  results: [],
}

const searchInitialState: searchState = {
  show: false,
  search: '',
  results: [],
}

const practiceInitialState: practiceState = {
  popup: false,
  movement: null,
  started: false,
  ended: false,
  playing: false,
  fullscreen: false,
  duration: 0,
  currentTime: 0,
  previous: '/practice',
}

const userInitialState: userState = {
  user: null,
  session: null,
}

export const appSlice = createSlice({
  'name': 'app',
  initialState,
  reducers: {
    'setLoaded': (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload
    },
    'setLoading': (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    'setWidth': (state, action: PayloadAction<number>) => {
      state.width = action.payload
    },
    'setHeight': (state, action: PayloadAction<number>) => {
      state.height = action.payload
    },
    'setMobile': (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload
    },
    'setTablet': (state, action: PayloadAction<boolean>) => {
      state.tablet = action.payload
    },
    'setVh': (state, action: PayloadAction<number>) => {
      state.vh = action.payload
    },
    'setScroll': (state, action: PayloadAction<number>) => {
      state.scroll = action.payload
    },
    'setRoute': (state, action: PayloadAction<string>) => {
      state.route = action.payload
    },
    'setFromRoute': (state, action: PayloadAction<string>) => {
      state.fromRoute = action.payload
    }
  }
})

export const filterSlice = createSlice({
  'name': 'filter',
  initialState: filterInitialState,
  reducers: {
    'setFilterShow': (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    },
    'setFilters': (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
    'setFilterResults': (state, action: PayloadAction<[]>) => {
      state.results = action.payload
    }
  }
})

export const searchSlice = createSlice({
  'name': 'search',
  initialState: searchInitialState,
  reducers: {
    'setSearchShow': (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    },
    'setSearch': (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    'setSearchResults': (state, action: PayloadAction<[]>) => {
      state.results = action.payload
    }
  }
})

export const practiceSlice = createSlice({
  name: 'practice',
  initialState: practiceInitialState,
  reducers: {
    'setPopup': (state, action: PayloadAction<boolean>) => {
      state.popup = action.payload
    },
    'setMovement': (state, action: PayloadAction<object | null>) => {
      state.movement = action.payload
    },
    'setStarted': (state, action: PayloadAction<boolean>) => {
      state.started = action.payload
    },
    'setEnded': (state, action: PayloadAction<boolean>) => {
      state.ended = action.payload
    },
    'setPlaying': (state, action: PayloadAction<boolean>) => {
      state.playing = action.payload
    },
    'setFullscreen': (state, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload
    },
    'setDuration': (state, action: PayloadAction<number>) => {
      state.duration = action.payload
    },
    'setCurrentTime': (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload
    },
    'setPrevious': (state, action: PayloadAction<string>) => {
      state.previous = action.payload
    }
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    'setUser': (state, action: PayloadAction<object | null>) => {
      state.user = action.payload
    },
    'setSession': (state, action: PayloadAction<object | null>) => {
      state.session = action.payload
    }
  }
})

export const { setLoaded, setLoading, setWidth, setHeight, setMobile, setTablet, setVh, setScroll, setRoute, setFromRoute } = appSlice.actions
export const { setFilterShow, setFilters, setFilterResults } = filterSlice.actions
export const { setSearchShow, setSearch, setSearchResults } = searchSlice.actions
export const { setPopup, setMovement, setStarted, setEnded, setPlaying, setFullscreen, setDuration, setCurrentTime, setPrevious } = practiceSlice.actions
export const { setUser, setSession } = userSlice.actions

export const appReducer = appSlice.reducer
export const filterReducer = filterSlice.reducer
export const searchReducer = searchSlice.reducer
export const practiceReducer = practiceSlice.reducer
export const userReducer = userSlice.reducer

