import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import PracticeItem from './base/PracticeItem'

export default function RecommendedRow({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 mobile:px-2.5 mobile:my-6"
      {...storyblokEditable(blok)}
    >
    <div className="border border-grey rounded-lg px-2.5 py-4 mx-3 bg-cream shadow-md mobile:px-2.5 mobile:py-3 mobile:mx-0">
      <div className="flex px-2.5 mb-20 items-start justify-between mobile:mb-10 mobile:px-0">
        <h2 className="text-lg text-slate leading-none mobile:text-md">{blok.title}</h2>
        <div>
          <p className="text-md uppercase mobile:text-m-sm mobile:pt-0.5">{blok.time}</p>
        </div>
      </div>
      <ul className="flex mobile:flex-wrap tablet:flex-wrap">
        {blok.practices && blok.practices.map((item: any) => (
          <PracticeItem
            key={item.id}
            practice={item}
            width={blok.style}
            classes="px-2.5 mobile:px-0"
          />
        ))}
      </ul>
    </div>
    </section>
  )
}
