import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import MovementItem from "./base/MovementItem"

export default function MovementRow({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-1.5 py-1 my-10 flex flex-col mobile:my-6"
      {...storyblokEditable(blok)}
    >
      <div className="grow flex items-center justify-between mx-1.5 pb-1.5 mb-5 text-base border-b border-slate mobile:mb-3 mobile:text-m-base">
        <div>
          <h5 className="uppercase">{blok.title}</h5>
        </div>
        <div>
          <h5 className="uppercase">{blok.byline}</h5>
        </div>
      </div>
      <ul className="flex flex-wrap justify-start">
        {blok.movements && blok.movements.map((item: any) => (
          <MovementItem key={item.id} movement={item} />
        ))}
      </ul>
    </section>
  )
}
