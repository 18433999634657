import { StoryblokComponent, storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

export default function MembershipCTA({ blok }: StoryblokData) {
  return (
    <section
      className="w-full px-3 my-10 mobile:my-6"
      {...storyblokEditable(blok)}
    >

    {blok.cards &&
      <ul className="flex justify-center items-start mobile:flex-wrap">
        {blok.cards.map((card: StoryblokData) => (
          <StoryblokComponent key={card._uid} blok={card} />
        ))}
      </ul>
    }
    </section>
  )
}
