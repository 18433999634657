import { Field } from 'formik'
import classNames from 'classnames'

const convertCentsToDollars = (cents: number) => {
  return cents / 100 
}

type SubscriptionItemProps = {
  id: string,
  name: string,
  price: number,
  active: boolean,
  type: 'field' | 'button',
}

export default function SubscriptionItem({ id, name, price, active, type = 'button' }: SubscriptionItemProps) {
  const labelClasses = 'text-base uppercase w-full flex mb-2.5 pt-2 pb-1.5 px-4 rounded-[20px] border border-slate bg-offwhite cursor-pointer'

  return (
    <div
      className="w-full"
    >
      {type === 'button' && (
        <button
          className={
            classNames(
              labelClasses,
              {
                '!bg-slate text-white': active,
              }
            )
          }
        >
          <span className="grow">{name}</span>
          <span>${convertCentsToDollars(price)}</span>
        </button>
      )}
      {type === 'field' && (
        <label
          className={
            classNames(
              labelClasses,
              {
                '!bg-slate text-white': active,
              }
            )
          }
        >
          <span className="grow">{name}</span>
          <Field
            name="membership"
            type="radio"
            value={id}
            className="form-input appearance-none border-white border rounded-full p-0 mr-1 opacity-0"
          />
          <span>${convertCentsToDollars(price)}</span>
        </label>
      )}
    </div>
  )
}
