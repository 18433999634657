import { HeartIcon } from '@heroicons/react/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid'
import classNames from 'classnames'

export default function SavePractice({ save, unsave, isSaved, isLoading }: any) {
  return (
    <div className="">
      {isSaved ? (
        <button
          className={
            classNames({
              'text-base flex items-center disabled:opacity-30 transition-all duration-200 mobile:text-m-base': true,
            })
          }
          onClick={unsave}
          disabled={isLoading}
        >
          <HeartIconSolid className="w-5 h-5 mr-1 text-slate mobile:w-3 mobile:h-3" />
          Saved 
        </button>
      ) : (
        <button
          className={
            classNames({
              'text-base flex items-center disabled:opacity-30 transition-all duration-200 mobile:text-m-base': true,
            })
          }
          onClick={save}
          disabled={isLoading}
        >
          <HeartIcon className="w-5 h-5 mr-1 text-grey mobile:w-3 mobile:h-3" />
          Add to Favourites
        </button>
      )}
    </div>
  )
}
