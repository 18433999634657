import { useRouter } from 'next/router'
import Image from 'next/image'
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'

import { StoryblokComponent, storyblokEditable } from "@storyblok/react"
import { render } from "storyblok-rich-text-react-renderer"

import { StoryblokData } from "@/services/types"

import Button from "@/components/base/Button"

export default function MovementProfile({ blok }: StoryblokData) {
  const router = useRouter()

  const supabaseClient = useSupabaseClient()
  const user = useUser()

  const signOut = async () => {
    await supabaseClient.auth.signOut()

    setTimeout(() => {
      router.push('/')
    }, 600)
  }

  return (
    <section
      key={blok._uid}
      className="w-full mt-[100px]"
      data-className="w-full px-1.5 py-1 flex mobile:px-2.5 mobile:min-h-[66vh] tablet:flex-col-reverse tablet:items-center tablet:mt-16"
      {...storyblokEditable(blok)}
    >
      {user ? (
        <>
          <div className="max-w-xl mx-auto mb-20 mobile:w-full mobile:px-2.5">
            <Button
              text="Sign Out"
              color="dark"
              clickEvent={signOut}
              size="regular"
              classes="!w-full text-center"
            />
          </div>
        </>
      ) : (
        <p></p>
      )}
        <div className="max-w-[640px] mx-auto mb-20 mobile:w-full mobile:px-2.5">
          <div className="w-full grow flex flex-col items-center justify-center text-center">
            <div className="w-full text-center mb-8 max-w-xl">
              <h3 className="text-[36px] leading-[1] mobile:text-m-lg">{blok.title}</h3>
            </div>
            {blok.qrImage &&
              <Image
                src={blok.qrImage.filename}
                alt={blok.qrImage.alt}
                width={240}
                height={240}
              />
            }
          </div>
          <div className="w-full flex flex-col items-center justify-center mt-6">
            <div className="w-full flex justify-center max-w-xl mx-auto">
              <Button
                text={blok.qrButton}
                color="dark"
                size="regular"
                link={true}
                linkUrl={blok.qrLink.url}
                externalLink={true}
                classes="!min-w-full text-center mt-2"
              />
            </div>
          </div>
        </div>
        <div className="max-w-[800px] mx-auto mb-12 mobile:w-full mobile:px-2.5">
          <div className="w-full flex flex-col items-center">
            {blok.faqs &&
              <ul className="w-full">
                {blok.faqs.map((item: StoryblokData) => (
                  <StoryblokComponent
                    blok={item}
                    key={item._uid}
                  />
                ))}
              </ul>
            }
          </div>
        </div>
        <div className="w-full max-w-xl mx-auto flex flex-col items-center">
          {blok.contact &&
            <div className="w-full prose text-base text-center flex flex-col items-center justify-center mt-3">
              {render(blok.contact)}
            </div>
          }          
        </div>
    </section>
  )
}
