import { useState } from 'react'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import classNames from 'classnames'

import { ArrowRightIcon } from '@heroicons/react/24/outline'

interface NewsletterValues {
  email: string,
}

type NewsletterProps = {
  title: string,
  placeholder: string,
  successMessage: string,
  success: boolean,
  setSuccess: any,
}

const BaseForm = (props: NewsletterProps & FormikProps<NewsletterValues>) => {
  const { title, placeholder, successMessage, success, touched, errors, isSubmitting } = props

  const errorClasses = 'text-sm uppercase mb-1 text-slate'

  return (
    <Form className="flex flex-col">
      <div className="w-full">
        <h5 className="uppercase mobile:text-m-base">{title}</h5>
      </div>
      <div
        className={
          classNames({
            "w-full": true,
            "opacity-60": isSubmitting,
            "hidden": success
          })
        }
      >
        {touched.email && errors.email && (<p className={errorClasses}>{errors.email}</p>)}
        <div className="relative">
          <Field name="email" type="email" placeholder={placeholder} className="form-input w-full text-base py-1.5 pl-0 pr-5 border-0 border-b border-slate bg-white focus:ring-0 mobile:text-m-base" />
          <button
            className={classNames({
              'absolute right-1 top-[50%] translate-y-[-50%]': true,
            })}
            type="submit"
          >
            <ArrowRightIcon className="w-3 h-3 text-slate" />
          </button>
        </div>
      </div>
      <div
        className={
          classNames({
            "w-full py-1.5": true,
            "hidden": !success
          })
        }
      >
        <p className="text-base text-slate mobile:text-m-base">{successMessage}</p>
      </div>
    </Form>
  )
}

type NewsletterFormProps = {
  errors?: string,
} & NewsletterProps

const NewsletterForm = withFormik<NewsletterFormProps, NewsletterValues>({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)

    const options = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }

    const response = await fetch('/api/mailerlite/subscribe', options)

    if (response.status === 200) {
      const data = await response.json()

      if (data.status === 'success') {
        props.setSuccess(true)
      }
    }
  }
})(BaseForm)

export default function Newsletter({ title, placeholder, successMessage }: any) {
  const [success, setSuccess] = useState(false)

  return (
    <div className="">
      <NewsletterForm
        title={title}
        placeholder={placeholder}
        successMessage={successMessage}
        success={success}
        setSuccess={setSuccess}
      />
    </div>
  )
}
