import { storyblokEditable } from "@storyblok/react"
import classNames from 'classnames'

import { StoryblokData } from "@/services/types"

import HeadingListContent from "@/components/HeadingListContent"

export default function HeadingListDetail({ blok, active, setActive }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 mobile:px-0"
      {...storyblokEditable(blok)}
      onMouseLeave={() => setActive(null)}
      onMouseEnter={() => setActive(blok._uid)}
    >
      <div className="w-full">
        <button
          className={
            classNames({
              '3xl:text-[174px] 2xl:text-[142px] xl:text-[108px] lg:text-[92px] md:text-[76px] leading-[0.9] uppercase whitespace-nowrap text-left transition-opacity duration-300 mobile:!text-[40px]': true,
              'opacity-10': active !== null && active !== blok._uid,
            })
          }
          onClick={() => setActive(blok._uid)}
        >
          {blok.title}
        </button>
        <div 
          className={
            classNames({
              "w-full h-full relative mobile-up:hidden": true,
              "hidden": active !== blok._uid,
            })
          }
        >
          <HeadingListContent
            key={blok._uid}
            description={blok.description}
            image={blok.image}
            group={blok.group}
            align={blok.align}
            width={blok.width}
            show={active === blok._uid}
          />
        </div>
      </div>
    </section>
  )
}
