import { storyblokEditable } from "@storyblok/react"
import { render } from 'storyblok-rich-text-react-renderer'

import { StoryblokData } from "../services/types"

import Button from "./base/Button"

export default function Heading({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 mobile:px-2.5 mobile:my-6"
      {...storyblokEditable(blok)}
    >
      <div className="prose text-lg w-full max-w-none mobile:text-m-lg">
        {render(blok.text)}
      </div>
      {blok.includeLink &&
        <div className="mt-10 mobile:mt-4">
          <Button
            text={blok.linkText}
            size="small"
            color="light"
            link={true}
            linkUrl={blok.link.url}
            classes="mobile:text-m-base mobile:w-full mobile:text-center"
          />
        </div>
      }
    </section>
  )
}
