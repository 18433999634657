import { useState } from 'react'
import Script from 'next/script'

import type { AppProps } from 'next/app'
import { createBrowserSupabaseClient, Session } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import { Provider } from 'react-redux'
import { storyblokInit, apiPlugin } from '@storyblok/react'

import { wrapper } from '@/services/store'

import '@/styles/globals.css'

// Container Components, map to Content Types in Storyblok
import Article from '@/components/Article'
import Page from '@/components/Page'
import Practice from '@/components/Practice'
import Movement from '@/components/Movement'
import Video from '@/components/Video'

// Universal Components, map to Components in storyblok
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import MenuLink from '@/components/MenuLink'

// Components
import AllResults from '@/components/AllResults'
import BlogRow from '@/components/BlogRow'
import ButtonLink from '@/components/ButtonLink'
import BlogArticles from '@/components/BlogArticles'
import CallToAction from '@/components/CallToAction'
import ContentBreak from '@/components/ContentBreak'
import Copy from '@/components/Copy'
import Details from '@/components/Details'
import DetailItem from '@/components/DetailItem'
import ExploreCTA from '@/components/ExploreCTA'
import FAQs from '@/components/FAQs'
import FAQItem from '@/components/FAQItem'
import Filters from '@/components/Filters'
import FilterResults from '@/components/FilterResults'
import Heading from '@/components/Heading'
import HeadingList from '@/components/HeadingList'
import HeadingListDetail from '@/components/HeadingListDetail'
import Hero from '@/components/Hero'
import HeroForm from '@/components/HeroForm'
import HeroPractice from '@/components/HeroPractice'
import HeroText from '@/components/HeroText'
import HeroVideo from '@/components/HeroVideo'
import Images from '@/components/Images'
import MembershipCard from '@/components/MembershipCard'
import MembershipCTA from '@/components/MembershipCTA'
import MovementRow from '@/components/MovementRow'
import MultiButtonLink from '@/components/MultiButtonLink'
import PageForm from '@/components/PageForm'
import PracticeRow from '@/components/PracticeRow'
import RecommendedRow from '@/components/RecommendedRow'
import SearchResults from '@/components/SearchResults'
import TagsRow from '@/components/TagsRow'
import TopicsRow from '@/components/TopicsRow'

// User
import UserPractice from '@/components/UserPractice'
import UserProfile from '@/components/UserProfile'

// Movement Components - Used for App & Account integrations with Movement
import MovementSignup from '@/components/movement/MovementSignup'
import MovementProfile from '@/components/movement/MovementProfile'

const components = {
  // Main Containers
  article: Article,
  page: Page,
  practice: Practice,
  movement: Movement,
  video: Video,

  // User
  userPractice: UserPractice,
  userProfile: UserProfile,

  // Universal components
  header: Header,
  footer: Footer,
  menuLink: MenuLink,
  filter: Filters,

  // General
  allResults: AllResults,
  blogRow: BlogRow,
  buttonLink: ButtonLink,
  blogArticles: BlogArticles,
  callToAction: CallToAction,
  contentBreak: ContentBreak,
  copy: Copy,
  details: Details,
  detail: DetailItem,
  exploreCTA: ExploreCTA,
  faqs: FAQs,
  faqItem: FAQItem,
  filterResults: FilterResults,
  heading: Heading,
  headingList: HeadingList,
  headingListDetail: HeadingListDetail,
  hero: Hero,
  heroForm: HeroForm,
  heroPractice: HeroPractice,
  heroText: HeroText,
  heroVideo: HeroVideo,
  images: Images,
  membershipCard: MembershipCard,
  membershipCTA: MembershipCTA,
  movementRow: MovementRow,
  multiButtonLink: MultiButtonLink,
  pageForm: PageForm,
  practiceRow: PracticeRow,
  recommendedRow: RecommendedRow,
  searchResults: SearchResults,
  tagsRow: TagsRow,
  topicsRow: TopicsRow,

  // Movement Components
  movementSignup: MovementSignup,
  movementProfile: MovementProfile,
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  use: [apiPlugin],
  apiOptions: {
    region: "us",
  },
  components,
})

export default function App({ Component, ...rest }: AppProps<{ initialSession: Session }>) {
  const [supabaseClient] = useState(() => createBrowserSupabaseClient())

  const { store, props } = wrapper.useWrappedStore(rest)
  const { pageProps } = props

  return (
    <SessionContextProvider supabaseClient={supabaseClient} initialSession={pageProps.initialSession}>
      <Provider store={store}>
        <Component {...pageProps} />
        <Script
          id="meta-pixel"
          strategy="afterInteractive"
        >
          {` 
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '748004996801792');
            fbq('track', 'PageView');
          `}
        </Script>
        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
        >
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P65HKSF');
          `}
        </Script>
      </Provider>
    </SessionContextProvider>
  )
}

