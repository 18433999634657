import { storyblokEditable } from "@storyblok/react"
import classNames from "classnames"

import { StoryblokData } from "../services/types"

import ImageBlock from "./ImageBlock"

export default function Images({ blok }: StoryblokData) {
  return (
    <section
      className={
        classNames({
          'w-full px-1.5 py-1 my-8 flex items-start': true,
          'justify-center': blok.align === 'center',
          'justify-start': blok.align === 'left',
          'justify-end': blok.align === 'right',
          'justify-between': blok.align === 'between',
        })
      }
      {...storyblokEditable(blok)}
    >
    <div
      className={
        classNames({
          'w-full flex items-center self-start mobile:!w-full': true,
          'w-full': blok.width === 'full',
          'w-2/3 tablet:w-3/4': blok.width === 'twoThirds',
          'w-1/2 tablet:w-2/3': blok.width === 'half',
          'w-1/3 tablet:w-1/2': blok.width === 'third',
          'w-1/4 tablet:w-1/3': blok.width === 'quarter',
        })
      }
    >
      {blok.images && blok.images.length > 0 &&
        blok.images.map((image: any) => (
          <ImageBlock
            key={image.id}
            image={image}
            classes={blok.images.length === 2 ? 'first:mr-5 mobile:first:mr-2': ''}
          />
        ))
      }
    </div>
    </section>
  )
}
