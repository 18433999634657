import { ArrowUpTrayIcon, PaperClipIcon } from '@heroicons/react/24/outline'

import classNames from 'classnames'

export default function SharePractice({ copy, isCopied }: any) {
  return (
    <div className="ml-5 mr-1 mobile:ml-3">
      <button
        className={
          classNames({
            'text-base flex items-center disabled:opacity-30 mobile:text-m-base': true,
          })
        }
        onClick={copy}
      >
       {isCopied ? (
          <>
          <PaperClipIcon className="w-5 h-5 mr-1 text-grey mobile:w-3 mobile:h-3" />
          Copied
          </>
        ) : (
          <>
          <ArrowUpTrayIcon className="w-5 h-5 mr-1 text-grey mobile:w-3 mobile:h-3" />
          Share
          </>
        )}
      </button>
    </div>
  )
}
