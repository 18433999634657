import { storyblokEditable, StoryblokComponent } from "@storyblok/react"

import { StoryblokData } from "@/services/types"

export default function Details({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full py-1 my-16 px-[10%] mobile:my-6 mobile:px-1.5"
      {...storyblokEditable(blok)}
    >
      {blok.content &&
        <ul className="flex justify-center mobile:flex-wrap">
          {blok.content.map((child: StoryblokData) => (
            <StoryblokComponent key={child._uid} blok={child} />
          ))}
        </ul>
      }
    </section>
  )
}
