import Link from 'next/link'
import Image from 'next/image'
import { useState, useRef, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

type LogoProps = {
  title: string,
  logo: string,
  height: string,
  width: string,
  active: boolean,
}

export default function LogoAnimate({ title, logo, height, width, active }: LogoProps) {
  const [lheight, setLHeight] = useState(40)
  const [animate, setAnimate] = useState(false)
  const [logoLoaded, setLogoLoaded] = useState(false)

  const logoRef = useRef(null)

  const scroll = useSelector((state: any) => state.app.scroll)
  const route = useSelector((state: any) => state.app.route)
  const fromRoute = useSelector((state: any) => state.app.fromRoute)
  const mobile = useSelector((state: any) => state.app.mobile)
  const loading = useSelector((state: any) => state.app.loading)
  const loaded = useSelector((state: any) => state.app.loaded)

  useLayoutEffect(() => {
    if (route === '/' && !mobile) {
      setAnimate(true)
    } else {
      setAnimate(false)
    }
  }, [route])

  useLayoutEffect(() => {
    if (!animate || mobile) {
      setLHeight(mobile ? 50 : 40)
      return
    }

    if (scroll < 20) {
      setLHeight(240)
    } else if (scroll < 400) {
      const newHeight = (240 - (window.scrollY - 20))

      const updateHeight = newHeight > 40 ? newHeight : 40

      setLHeight(updateHeight)
    }
  }, [scroll, animate])

  return (
    <h1 className="flex flex-col">
      <span className="sr-only">{title}</span>
      <div
        ref={logoRef}
        className={
          classNames({
            "transition-all ease": true,
            "!duration-[0ms]": fromRoute === '/' && (route !== '/account/create' || route !== '/account/login'),
            "duration-[400ms]": scroll === 0,
            "duration-[16ms]": (route === '/' || active) && scroll > 10,
            "duration-[320ms]": route !== '/' || loading || !loaded,
          })
        }
        style={{ height: `${lheight}px` }}
      >
        <Link
          href="/"
          className="flex relative w-full h-full"
        >
          <Image
            src={logo}
            alt="Rise Movement"
            fill={true}
            priority={true}
            onLoadingComplete={() => setLogoLoaded(true)}
            sizes="20vw"
            className={
              classNames({
                "object-contain mobile:object-left transition-all ease-in-out duration-300 opacity-0": true,
                "opacity-100": logoLoaded,
              })
            }
          />
          {/*
          <img src={logo} alt="Rise Movement" className="absolute top-0 left-0 w-full h-full object-contain mobile:object-left" />
          */}
        </Link>
      </div>
    </h1>
  )
}
