import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import Button from './base/Button'

export default function ExploreCTA({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 mobile:px-2.5 mobile:my-6"
      {...storyblokEditable(blok)}
    >
     <div className="flex flex-col justify-between border-l border-slate pl-3.5 pt-4 pb-2.5 min-h-[240px] md:h-[calc((75vw_-_3rem)_/_2_/_1.4)] xl:h-[calc((83.66vw_-_5rem)_/_3_/_1.5)] mobile:border-l-0 mobile:px-0 mobile:min-h-[unset]">
      <h2 className="text-lg text-slate mobile:text-m-lg">{blok.title}</h2>
      <div className="flex items-start justify-between relative mobile:flex-col mobile:mt-2.5 tablet:flex-col">
        {blok.tags && blok.tags.length > 0 &&
          <ul className="w-full flex flex-wrap mr-[200px] mobile:mr-0 mobile:justify-start mobile:overflow-x-auto mobile:flex-nowrap mobile:pb-2.5 tablet:mr-0 tablet:flex-nowrap tablet:overflow-x-auto tablet:pb-1">
            {blok.tags.map((tag: any) => (
              <li
                key={tag.id}
                className="m-1.5 last:mr-0 mobile:mx-0.5 mobile:m-0.5 mobile:flex-shrink-0 tablet:flex-shrink-0"
              >
                <Button
                  text={tag.name}
                  color="light"
                  size="small"
                  link={true}
                  linkUrl={`/practice/filters?tags=${tag.slug}`}
                  classes="mobile:!text-m-base"
                />
              </li>
            ))}
          </ul>
        }
        <div className="m-1.5 mr-0 absolute right-0 mobile:relative mobile:w-full mobile:m-0 mobile:mt-0 tablet:relative tablet:w-full tablet:mt-0">
          {blok.link && blok.link.cached_url !== '' &&
            <Button
              text={blok.linkText}
              color="dark"
              size="small"
              link={true}
              linkUrl={blok.link.url}
              classes="whitespace-nowrap mobile:w-full mobile:text-m-base mobile:text-center"
            />
          }
        </div>
      </div>
     </div>
    </section>
  )
}
