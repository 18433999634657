import Link from 'next/link'
import { storyblokEditable } from "@storyblok/react"
import classNames from "classnames"

import { StoryblokData } from "@/services/types"

interface ButtonComponent {
  text: string,
  color: string,
  size: string,
  style?: string,
  link?: boolean,
  linkUrl?: string | undefined,
  clickEvent?: any,
  disabled?: boolean,
  classes?: string
}

const MultiButton = ({ text, color, size, style, link, linkUrl, clickEvent, disabled, classes }: ButtonComponent) => {
  const baseClasses = style === 'text'
    ? 'hidden'
    : 'hidden w-full appearance-none inline-block w-fit text-base text-left uppercase leading-none rounded-[20px] border border-slate self-start disabled:opacity-50 disabled:cursor-not-allowed'
  const url = linkUrl ? linkUrl : '#'

  if (link) {
    return  (
      <Link
        href={url}
        className={
          classNames(
            size === 'small' ? 'pb-1 pt-1 px-6' : 'pb-2.5 pt-2.5 px-8',
            style === 'text' ? '!p-0' : '',
            {
              'bg-offwhite text-slate hover:bg-slate hover:text-white': color === 'light',
              'bg-slate text-white hover:bg-offwhite hover:text-slate': color === 'dark',
              [baseClasses]: true,
            },
            classes ? [classes] : null,
          )
        }
      >
        {text}
      </Link>
    )
  } else {
    return (
      <>
        {clickEvent ?
          <button
            className={
              classNames(
                size === 'small' ? 'pb-1 pt-1 px-6' : 'pb-2.5 pt-2.5 px-8',
                color === 'light' ? 'bg-offwhite text-slate hover:bg-slate hover:text-white' : 'bg-slate text-white hover:bg-offwhite hover:text-slate',
                { [baseClasses]: true },
                classes ? [classes] : null,
              )
            }
            disabled={disabled}
            onClick={clickEvent}
          >
            {text}
          </button>
        :
          <button
            className={
              classNames(
                size === 'small' ? 'pb-1 pt-1 px-3' : 'pb-2.5 pt-2.5 px-5',
                color === 'light' ? 'bg-offwhite text-slate hover:bg-slate hover:text-white' : 'bg-slate text-white hover:bg-offwhite hover:text-slate',
                { [baseClasses]: true, },
                classes ? [classes] : null,
              )
            }
            disabled={disabled}
            type="submit"
          >
            {text}
          </button>
        }
    </>
    )
  }
}

export default function MultiButtonLink({ blok }: StoryblokData) {
  const url = blok.link.linktype === "url" ? blok.link.url : `/${blok.link.cached_url}`

  const classes = blok.size === 'large' ? 'text-lg px-10 !pt-2.5 !pb-2 !rounded-[50px]' : (blok.size === 'medium' ? 'text-md' : 'text-base')

  return (
    <section
      key={blok._uid}
      className="w-full flex justify-center px-2.5 py-1"
      {...storyblokEditable(blok)}
    >
      <MultiButton
        text={blok.text}
        color={blok.color}
        link={true}
        linkUrl={url}
        size={blok.size}
        classes={classes}
      />
    </section>
  )
}
