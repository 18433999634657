import { storyblokEditable } from "@storyblok/react"
import { render } from 'storyblok-rich-text-react-renderer'

import { StoryblokData } from "../services/types"

import ImageBlock from './ImageBlock'

export default function DetailItem({ blok }: StoryblokData) {
  return (
    <li
      {...storyblokEditable(blok)}
      className="w-1/6 px-2.5 mobile:w-1/2 mobile:px-1.5 mobile:py-3"
    >
      <div className="w-full mb-2.5 mobile:mb-2">
        {blok.image &&
          <ImageBlock image={blok.image} />
        }
      </div>
      <h5 className="uppercase leading-tight text-base mobile:text-m-base">{blok.title}</h5>
      {blok.includeByline &&
        <div className="prose text-grey text-base mobile:text-m-base [&_a]:text-grey [&_a]:no-underline [&_a]:hover:underline">
          {render(blok.byline)}
        </div>
      }
      {blok.includeDescription &&
        <div className="prose text-slate mt-3 text-sm leading-tight mobile:text-m-sm">
          {render(blok.description)}
        </div>
      }
    </li>
  )
}
