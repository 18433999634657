import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Player, ControlBar, BigPlayButton, ProgressControl, FullscreenToggle, VolumeMenuButton, CurrentTimeDisplay } from 'video-react'
import 'video-react/dist/video-react.css'
import HLs from 'hls.js'

import { setStarted, setPlaying, setFullscreen, setEnded, setDuration, setCurrentTime } from '../services/state'

const HLSSource = ({ src, video }: any) => {
  useEffect(() => {
    const hls = new HLs()

    if (HLs.isSupported()) {
      hls.loadSource(src)
      hls.attachMedia(video)

      hls.on(HLs.Events.MANIFEST_PARSED, () => {
        video.setAttribute('disablePictureInPicture', true)
      })
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [])


  return (
    <source
      src={src}
      type="application/x-mpegURL"
    />
  )
}

export default function VideoReactPlayer({ url, poster }: any) {
  const [ready, setReady] = useState(false)

  const playerRef = useRef<any>(null)

  const fullscreen = useSelector((state: any) => state.practice.fullscreen)
  const playing = useSelector((state: any) => state.practice.playing)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!playerRef.current) {
      return
    }

    const player = playerRef.current

    player.subscribeToStateChange((state: any) => {
      if (state.readyState >= 3) {
        setReady(true)
      }

      if (state.hasStarted) {
        dispatch(setStarted(true))
      }

      if (state.readyState >= 3) {
        dispatch(setPlaying(!state.paused))
      }

      if (state.readyState >= 3) {
        dispatch(setFullscreen(state.isFullscreen))
      }

      if (state.ended === true) {
        dispatch(setEnded(true))
      } else {
        dispatch(setEnded(false))
      }
    })
  }, [playerRef])

  useEffect(() => {
    if (!playerRef.current) {
      return
    }

    if (ready && playerRef.current.getState().player.paused !== !playing) {
      playerRef.current.play()
    }
  }, [playing])

  useEffect(() => {
    if (!playerRef.current) {
      return
    }

    if (ready && playerRef.current.getState().player.isFullscreen !== fullscreen) {
      playerRef.current.play()
    
      // go into fullscreen after slight deplay to ensure playing on mobile
      setTimeout(() => {
        playerRef.current.toggleFullscreen()
      }, 100)
    }
  }, [fullscreen])

  const handleFullscreen = () => {
    playerRef.current.toggleFullscreen()
  }

  return (
    <div 
      className="w-full h-full relative aspect-w-16 aspect-h-9"
    >
      <div>
        <Player
          ref={playerRef}
          fluid={true}
          autoPlay={false}
          playsInline={true}
          poster={poster}
        >
          <HLSSource
            src={url}
            isVideoChild={true}
          />

          <ControlBar
            autoHide={false}
            disableDefaultControls={true}
          >
            <VolumeMenuButton />
            <ProgressControl />
            <CurrentTimeDisplay />
            <FullscreenToggle actions={{ toggleFullscreen: handleFullscreen }} />
          </ControlBar>
          <BigPlayButton position="center" />
        </Player>
      </div>
    </div>
  )
}
