import { useState, useEffect } from 'react'
import Link from 'next/link'
import { StoryblokComponent, storyblokEditable } from "@storyblok/react"
import { useUser } from '@supabase/auth-helpers-react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { StoryblokData } from '../services/types'

import LogoAnimate from '@/components/LogoAnimate'
import ExploreButton from '@/components/ExploreButton'
import MyPracticeButton from '@/components/MyPracticeButton'
import FilterButton from '@/components/FilterButton'
import ClearFilterButton from '@/components/ClearFilterButton'
import SearchButton from '@/components/SearchButton'
import SeriesButton from '@/components/SeriesButton'

export default function Header({ blok }: StoryblokData) {
  const [active, setActive] = useState(false)
  const [fixed, setFixed] = useState(false)

  const route = useSelector((state: any) => state.app.route)
  const scroll = useSelector((state: any) => state.app.scroll) 
  const filters = useSelector((state: any) => state.filter.filters)
  const mobile = useSelector((state: any) => state.app.mobile)

  const user = useUser()

  useEffect(() => {
    if (active) {
      setActive(false)
    }
  }, [route])

  useEffect(() => {
    if (scroll > 30) {
      setFixed(true)
    } else {
      setFixed(false)
    }
  }, [scroll])
  
  return (
      <>
        <header
          key={blok._uid}
          className={
            classNames({
              'w-full absolute left-0 h-[60px] z-20 bg-offwhite px-3 py-2 text-base min-h-[60px] transition-all duration-400 mobile:py-3': true,
              'mobile:h-[100px]': mobile,
              '!fixed !top-0': route === '/' || route === '/contemplation',
            })
          }
          {...storyblokEditable(blok)}
        >
          {/* Top Bar */}
          <div className="flex w-full justify-between items-start z-20 relative mobile:items-center mobile:h-full">
            <>
              <div className="w-1/3 mobile:order-3 mobile:text-right">
                <button
                  className="text-base uppercase"
                  onClick={() => setActive(!active)}
                >
                  {active ? 'Close' : 'Menu'}
                </button>
              </div>
              <div className="w-1/3 text-center z-50 mobile:order-1 h-auto">
                <LogoAnimate
                  title="Rise Movement"
                  logo={blok.logo.filename}
                  height={blok.logo.height}
                  width={blok.logo.width}
                  active={active}
                />
              </div>
              <div className="w-1/3 mobile:hidden">
                {!user && blok.accountMenu && 
                  <ul className="flex justify-end">
                    {blok.accountMenu.map((item: any) => (
                      <li
                        key={item._uid}
                        className="mx-1.5 last:mr-0"
                      >
                        <StoryblokComponent
                          blok={item}
                          size="small"
                         /> 
                     </li>
                    ))}
                  </ul>
                }
                {user && blok.loggedInMenu && 
                  <ul className="flex justify-end">
                    {blok.loggedInMenu.map((item: any) => (
                      <li
                        key={item._uid}
                        className="mx-1.5 last:mr-0"
                      >
                        <StoryblokComponent
                          blok={item}
                          size="small"
                          classes={item.title === 'Profile' ? 'uppercase mx-2 !mt-1 inline-block hover:!bg-[rgba(0,0,0,0)] hover:underline hover:!text-slate' : ''}
                         /> 
                     </li>
                    ))}
                  </ul>
                }
              </div>
              {/* Context Bar */}
              {user &&
                <aside
                  className={
                    classNames({
                      'w-full fixed top-[30px] left-0 z-10 bg-offwhite px-3 pt-1.5 pb-2 text-base transition-all duration-400 mobile:top-[74px] mobile:px-1.5': true,
                      '!top-0 !fixed duration-[100ms]': fixed,
                      '!top-[30px] mobile:!top-[74px]': route === '/',
                    })
                  }
                >
                  <div className="flex justify-between w-full relative">
                    <div className="w-1/3 mobile:hidden">
                      <ul className="flex">
                        <li className="mr-2.5">
                          <ExploreButton />
                        </li>
                        <li className="mr-2.5">
                          <SeriesButton />
                        </li>
                        <li>
                          <MyPracticeButton />
                        </li>
                      </ul>
                    </div>
                    <div className="w-1/3 flex justify-end mobile:hidden">
                      <ul className="flex">
                        <li className="mr-2.5 relative">
                          <SearchButton />
                        </li>
                        {filters && filters.length > 0 &&
                          <li className="mr-2.5 relative">
                            <ClearFilterButton />
                          </li>
                        }
                        <li>
                          <FilterButton />
                        </li>
                      </ul>
                    </div>
                    <div className="w-full mobile-up:hidden">
                      <ul className="flex flex-wrap">
                        <li className="w-1/3 mobile:px-1">
                          <ExploreButton />
                        </li>
                        <li className="w-1/3 mobile:px-1">
                          <MyPracticeButton />
                        </li>
                        <li className="w-1/3 mobile:px-1">
                          <SeriesButton />
                        </li>
                        <li className="w-1/3 pt-2 mobile:px-1 relative">
                          <SearchButton />
                        </li>
                        <li className="w-1/3 pt-2 mobile:px-1">
                          <FilterButton />
                        </li>
                        {filters && filters.length > 0 &&
                          <li className="w-1/3 px-1 pt-2 relative">
                            <ClearFilterButton />
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </aside>
              }
            </>
          </div>
        </header>
      {/* Navigation Bar */}
      <aside
        className={
          classNames({
            'w-full h-screen bg-cream fixed left-0 top-0 z-30 transition-all duration-700 opacity-100': true,
            'top-[110vh] opacity-0': !active,
          })
        }
      >
        <section className="w-full flex h-[60px] px-3 py-2 mobile:items-center mobile:justify-between mobile:min-h-[100px] mobile:py-3">
          <div className="flex w-1/3 justify-start items-start mobile:order-2 mobile:justify-end">
            <button
              className="text-base uppercase"
              onClick={() => setActive(!active)}
            >
              {active ? 'Close' : 'Menu'}
            </button>
          </div>
          <div className="w-1/3 text-center">
            <div
              className={
                classNames({
                  'h-10 transition-opacity duration-[500ms] ease opacity-0 mobile:h-[50px]': true,
                  'opacity-100 delay-700': active,
                })
              }
            >
              <Link
                href="/"
                className="flex relative w-full h-full"
              >
                <img
                  src={blok.logo.filename}
                  alt="Rise Movement"
                  className="absolute top-0 left-0 w-full h-full object-contain mobile:object-left"
                />
              </Link>
            </div>
          </div>
        </section>
        <section
          className={
            classNames({
              'w-full px-3 mobile:px-2.5': true,
              'hidden': !active,
            })
          }
        >
          {blok.menu && blok.menu.length > 0 && (
            <nav className={
              classNames({
                'opacity-0 transition-opacity duration-[500ms] ease': true,
                'opacity-100 delay-700': active,
              })
            }>
              <ul className="flex flex-col mobile:mt-8">
                {blok.menu.map((item: any) => (
                  <li
                    key={item._uid}
                    className={
                      classNames({
                        "mb-2": true,
                        "hidden": !user && item.loggedInOnly
                      })
                    }
                  >
                    <StoryblokComponent
                      blok={item}
                      size="xlarge"
                      classes="text-xl mobile:!text-m-lg mobile:!leading-[1.45]"
                    />
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </section>
      </aside>
      {/* Mobile Account Menu */}
      {!user && blok.accountMenu && mobile &&
        <aside
          className={
            classNames({
              'w-full h-12 px-1 py-3 fixed bottom-0 left-0 z-30 transition-all duration-700 opacity-100 bg-offwhite shadow-[0px_5px_15px_2px_rgba(0,0,0,0.2)] mobile-up:hidden': true,
            })
          }
        >
          <ul className="flex">
          {!user && blok.accountMenu && 
            blok.accountMenu.map((item: any) => (
              <li
                key={item._uid}
                className="mx-1.5 w-1/2"
              >
                <StoryblokComponent
                  blok={item}
                  size="small"
                  classes="!w-full !text-center"
                 /> 
             </li>
            ))}
          </ul>
        </aside>
      }
    </>
  )
}
