import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { withFormik, FormikProps, Form, Field } from 'formik'
import * as Yup from 'yup'
import { CardComponent, CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper'
import { useUser } from '@supabase/auth-helpers-react'
import { ArrowSmallRightIcon, CheckIcon } from '@heroicons/react/24/outline'
import { createCustomerWithToken, createSubscription, createSupabaseSubscription, updateSupabaseAuthUserMetadata, verifyCoupon, errorClasses } from '@/services/utils'
import classNames from 'classnames'

import Button from '@/components/base/Button'
import SubscriptionItem from '@/components/forms/SubscriptionItem'

const options = {
  fonts: [
    { fontFamily: 'neue-haas', src: "url('https://rise-platform.vercel.app/fonts/NeueHaasDisplay-Roman.woff') format('woff')" },
  ],
  styles: {
    base: {
      fontSize: '14px',
      fontFamily: 'neue-haas, helvetica, arial, sans-serif',
      fontWeight: '300',
      lineHeight: '1.2em',
      color: '#505050',
      '::placeholder': {
        color: '#6b7280',
      }
    },
    empty: {
      '::placeholder': {
        color: '#6b7280',
      }
    }
  }
}

interface AddAccountValues {
  firstName: string,
  lastName: string,
  membership: string,
  cardNumber: number,
  cardExpiry: number,
  cardCvv: number,
  coupon?: string,
  consent: boolean,
}

type OtherProps = {
  userAction: any,
  cardRef: any,
  cbInstance: any,
  items: any,
}

const BaseForm = (props: OtherProps & FormikProps<AddAccountValues>) => {
  const [validCoupon, setValidCoupon] = useState<boolean | null>(null)
  const { touched, errors, isSubmitting } = props

  const fieldClasses = 'form-input w-full mb-2.5 text-base pt-2 pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'

  const handleCardChange = (event: any) => {
    if (event.field === 'number') {
      if (event.complete && event.error === undefined) {
        props.setFieldValue('cardNumber', 1)
      } else {
        props.setFieldValue('cardNumber', 0)
      }
    }
    if (event.field === 'expiry') {
      if (event.complete && event.error === undefined) {
        props.setFieldValue('cardExpiry', 1)
      } else {
        props.setFieldValue('cardExpiry', 0)
      }
    }
    if (event.field === 'cvv') {
      if (event.complete && event.error === undefined) {
        props.setFieldValue('cardCvv', 1)
      } else {
        props.setFieldValue('cardCvv', 0)
      }
    }
  }

  const checkCoupon = async (evt: any) => {
    evt.preventDefault()
    
    // @ts-ignore
    const { coupon } = await verifyCoupon(props.values.coupon)

    if (coupon && coupon.status === 'active') {
      setValidCoupon(true)
    }
  }

  return (
      <Form
        className={
          classNames({
            'flex flex-col w-full my-10': true,
            'opacity-40': isSubmitting,
          })
        }
      >
        <section className="mb-5">
          <h5 className="text-xs uppercase text-center mb-2">Details</h5>
          <div className="w-full">
            {touched.firstName && errors.firstName && (<p className={errorClasses}>{errors.firstName}</p>)}
            <Field name="firstName" type="text" placeholder="First Name" className={fieldClasses} />
          </div>
          <div>
            {touched.lastName && errors.lastName && (<p className={errorClasses}>{errors.lastName}</p>)}
            <Field name="lastName" type="text" placeholder="Last Name" className={fieldClasses} />
          </div>
        </section>

        <section className="mb-5">
          <h5 className="text-xs uppercase text-center mb-2">Subscription</h5>
          {touched.membership && errors.membership && <div className={errorClasses}>{errors.membership}</div>}
          {props.items && props.items.length > 0 && props.items.map((item: any) => (
            <SubscriptionItem
              key={item.item_price.id}
              {...item.item_price}
              active={props.values.membership === item.item_price.id}
              values={props.values}
            />
          ))}
        </section>

        <section>
          <h5 className="text-xs uppercase text-center mb-2">Payment</h5>
          {props.cbInstance &&
            <CardComponent
              ref={props.cardRef}
              fonts={options.fonts}
              styles={options.styles}
              onChange={handleCardChange}
            >
              <div className="w-full">
                {touched.cardNumber && errors.cardNumber && (<p className={errorClasses}>{errors.cardNumber}</p>)}
                <Field name="cardNumber" component={CardNumber} className={fieldClasses} />
              </div>
              <div className="w-full flex">
                <div className="w-1/2 mr-2.5">
                  {touched.cardExpiry && errors.cardExpiry && (<p className={errorClasses}>{errors.cardExpiry}</p>)}
                  <Field name="cardExpiry" component={CardExpiry} className={fieldClasses} />
                </div>
                <div className="w-1/2">
                  {touched.cardCvv && errors.cardCvv && (<p className={errorClasses}>{errors.cardCvv}</p>)}
                  <Field name="cardCvv" component={CardCVV} className={fieldClasses} />
                </div>
              </div>
            </CardComponent>
          }
        </section>

      <div className="flex flex-col w-full mt-4">
        <div className="flex items-center mb-2.5 relative mobile-up:mx-1">
          <Field type="text" name="coupon" placeholder="Enter promo code" className="w-full text-base focus:outline-0 focus:ring-0 px-0 py-1 bg-[transparent] border-0 !border-b border-grey" />
          {!validCoupon ? (
            <button
              className="absolute right-0"
              onClick={checkCoupon}
            >
              <ArrowSmallRightIcon className="w-4 h-4 ml-2" />
            </button>
          ) : ( 
            <button
              className="absolute right-0"
              onClick={checkCoupon}
            >
              <CheckIcon className="w-4 h-4 ml-2 text-[green]" />
            </button>
          )}
        </div>
      </div>
      <div className="w-full mt-10 mb-5 mobile-up:ml-2">
        {touched.consent && errors.consent && (<p className={errorClasses + ' !mb-3 !ml-5'}>{errors.consent}</p>)}
        <label className="flex">
          <Field type="checkbox" name="consent" />
          <span className="ml-2.5 text-base mobile:text-m-base">I agree to the <a href="/terms-of-use" className="underline" target="_blank" rel="noopener noreferrer">Terms</a>, <a href="/privacy-policy" className="underline" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and end user license agreement</span>
        </label>
      </div>
      <div className="w-full flex justify-between items-end mobile:flex-col mobile:justify-start mobile:items-start">
        <Button
          text="Add Account"
          size="small"
          classes="text-center w-fit px-12 min-w-[160px] mobile:w-full"
          color="dark"
          disabled={isSubmitting}
        />
      </div>
    </Form>
  )
}

interface FormProps {
  [key: string]: any,
  handleSubmit: any,
  cardRef: any,
}

const AddAccountForm = withFormik<FormProps, AddAccountValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    firstName: '',
    lastName: '',
    membership: props.membership,
    cardNumber: 0,
    cardExpiry: 0,
    cardCvv: 0,
    consent: false,
  }),
  validationSchema: Yup.object({
    firstName: Yup.string().required('First name is Required'),
    lastName: Yup.string().required('Last name is Required'),
    membership: Yup.string().required('Please select a membership'),
    cardNumber: Yup.number().required('Required').min(1, 'Valid card required'),
    cardExpiry: Yup.number().required('Required').min(1, 'Valid expiry required'),
    cardCvv: Yup.number().required('Required').min(1, 'Valid CVV required'),
    consent: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  }),
  handleSubmit: async (values: any, { props, setSubmitting }) => {
    setSubmitting(true) 

    props.cardRef.current.tokenize().then((payload: any) => {
      props.handleSubmit({ ...values, ...payload })
    })
  }
})(BaseForm)

export default function AddAccount() {
  const [subscriptionItems, setSubscriptionItems] = useState([])
  const [cbInstance, setCbInstance] = useState<any>(null)
  const cardRef = useRef<any>(null)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [membership, setMembership] = useState<string | null>(null)

  const [token, setToken] = useState<string | null>(null)
  const [coupon, setCoupon] = useState<string | null>(null)

  const user = useUser()
  const router = useRouter()

  useEffect(() => {
    const fetchSubscriptionItems = async () => {
      const url = '/api/chargebee/items/prices'

      const response = await fetch(url)

      const { list } = await response.json()
      const orderedList = list.sort((a: any, b: any) => a.item_price.price - b.item_price.price)
      
      setSubscriptionItems(orderedList)
    }

    fetchSubscriptionItems()
  }, [])

  useEffect(() => {
    if (!(window as any).Chargebee) {
      return
    } else {
      if (!cbInstance) {
        setCbInstance(
          (window as any).Chargebee.init({
            site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
            publishableKey: process.env.NEXT_PUBLIC_CHARGEBEE_PUBLISHABLE_KEY,
          })
        )
      }
    }
  }, [])

  const handleAddAccount = async (values: any) => {
    setFirstName(values.firstName)
    setLastName(values.lastName)
    setMembership(values.membership)
    setToken(values.token)

    const { customer, card } = await createCustomerWithToken(values.firstName, values.lastName, user?.user_metadata.email, values.token)

    const subscription = await createSubscription(customer.id, (values.membership as string), card.payment_source_id, values.coupon ? values.coupon : null)

    const supabaseUser = await updateSupabaseAuthUserMetadata(values.firstName, values.lastName, subscription.customer.id)

    await createSupabaseSubscription(supabaseUser.user.id, subscription.subscription.id, subscription.subscription.status)

    setTimeout(() => {
      router.replace('/practice') 
    }, 500)
  }

  return (
    <div className="w-[66%] max-w-lg h-full flex flex-col relative mobile:w-full mobile:max-w-none mobile:pb-24">
      {user && (
        <>
          <div className="w-full h-full flex flex-col mt-10">
            <p className="text-base">There is currently no account or subscription associated with this social login. Please create one now to use Rise.</p>
          </div>
          <section className="w-full flex justify-center items-center grow h-full">
            <AddAccountForm
              handleSubmit={handleAddAccount}
              cardRef={cardRef}
              cbInstance={cbInstance}
              items={subscriptionItems}
            />
          </section>
        </>
      )}
    </div>
  )
}
