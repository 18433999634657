import { useState, useRef, useEffect } from 'react'

import Glide from '@glidejs/glide'
import 'node_modules/@glidejs/glide/dist/css/glide.core.min.css'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

import { StoryblokData } from '@/services/types'

import GalleryItem from '@/components/GalleryItem'

export default function Gallery({ blok }: StoryblokData) {
  const [glide, setGlide] = useState<any>(null)

  const galleryRef = useRef(null)

  useEffect(() => {
    if (!galleryRef.current) {
      return
    }

    const practiceGlide = new Glide(galleryRef.current, {
      type: 'carousel',
      startAt: 0,
      perView: 3,
      bound: true,
      gap: 20,
      hoverpause: true,
      animationDuration: 400,
      breakpoints: {
        1280: {
          perView: 2,
        },
        768: {
          perView: 1,
          peek: {
            before: 0,
            after: 90,
          },
          gap: 12,
        }
      }
    })

    practiceGlide.mount()

    setGlide(practiceGlide)

    return () => {
      practiceGlide.destroy()
    }
  }, [])

  const handleNext = () => {
    if (glide)
      glide.go('>')
  }

  const handlePrev = () => {
    if (glide)
      glide.go('<')
  }

  return (
    <div
      className="glide w-full"
      ref={galleryRef}
    >
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {blok.practices && blok.practices.map((item: any) => (
            <GalleryItem key={item.id} practice={item} />
          ))}
        </ul>
      </div>
      <div className="glide__arrows" data-glide-el="controls">
        {glide && glide.index > 0 && (
          <button
            className="glide__arrow glide__arrow--left absolute top-[50%] translate-y-[-50%] left-[-16px]"
            onClick={handlePrev}
          >
            <ChevronLeftIcon className="w-24 h-24 text-white stroke-[0.5]" />
          </button>
        )}
        {glide && glide.index < blok.practices.length - 3 && (
          <button
            className="absolute top-[50%] translate-y-[-50%] right-[-16px]"
            onClick={handleNext}
          >
            <ChevronRightIcon className="w-24 h-24 text-white stroke-[0.5]" />
          </button>
        )}
      </div>
    </div>
  )
}
