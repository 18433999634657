import Link from 'next/link'
import { StoryblokData } from "../services/types"

import classNames from "classnames"

import ImageBlock from './ImageBlock'

export default function BlogItem({ content, row, slug }: StoryblokData) {
  const linkUrl = `/contemplation/${slug}`

  return (
    <li
      className={
        classNames({
          'w-1/2 px-6 mobile:w-full mobile:px-1': true,
          'mb-32 mobile:mb-10': !row,
        })
      }
    >
      <article
        className={
          classNames({
            'w-full min-w-max flex pl-2.5 py-3 pr-4 border-l border-r border-slate mobile:px-2 mobile:py-2': true,
          })
        }
      >
        <div className="w-full h-auto flex flex-col justify-between px-1.5 mobile:grow">
          <h5 className="uppercase leading-[1.1] pr-10 text-md mobile:text-base mobile:pr-0">
            <Link href={linkUrl}>
              {content.title}
            </Link>
          </h5>
          <div className="flex">
            <Link href={linkUrl} className="text-base uppercase hover:underline">
              Read more
            </Link>
          </div>
        </div>
        <div className="w-full px-1.5 mobile:px-0">
          {content.thumbnail &&
            <Link href={linkUrl}>
              <ImageBlock image={content.thumbnail} />
            </Link>
          }
        </div>
      </article>
    </li>
  )
}
