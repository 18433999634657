import { storyblokEditable, SbBlokData } from "@storyblok/react"
import { render } from "storyblok-rich-text-react-renderer"
import { StoryblokData } from "@/services/types"
import classNames from 'classnames'

import Button from "./base/Button"

export default function CallToAction({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-12 text-center flex flex-col items-center justify-center mobile:my-6"
      {...storyblokEditable(blok)}
    >
      <div className="text-lg prose mb-6 mobile:text-m-lg mobile:mb-5">
        {render(blok.title)}
      </div>
      {blok.includeDescription && (
        <div
          className={
            classNames({
              'prose max-w-md mobile:text-m-base': true,
              'text-base max-w-[640px] mobile:text-m-base': blok.descriptionSize === 'small',
              'text-md max-w-[900px] mobile:text-m-md': blok.descriptionSize === 'medium',
              'text-lg max-w-[1024px] mobile:text-m-lg': blok.descriptionSize === 'large',
            })
          }
        >
          {render(blok.description)}
        </div>
      )}
      {blok.includeLink &&
        <div
          className={
            classNames({
              'mobile:w-full mt-3': true,
              '!mt-8 mobile:!mt-5': blok.includeDescription,
            })
          }
        >
          <Button
            text={blok.linkText}
            size="small"
            color="light"
            link={true}
            linkUrl={blok.link.url}
            classes="mobile:w-full mobile:text-center mobile:text-m-base"
          />
        </div>
      }
    </section>
  )
}
