import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import Button from '@/components/base/Button'

interface CancelValues {
  reason: string,
  feedback: string,
}

type OtherProps = {
  userAction: any
}

const BaseForm = (props: OtherProps & FormikProps<CancelValues>) => {
  const { touched, errors, isSubmitting } = props

  const checkboxClasses = 'appearance-none focus:appearance-none indeterminate:appearance-none hover:outline-none mr-3 border border-white bg-slate checked:bg-white checked:outline-none hover:appearance-none checked:!appearance-none'
  const errorClasses = 'text-sm uppercase mb-1 text-white'

  return (
    <Form className="flex flex-col">
      <div className="w-full mb-2.5 hidden">
        <p className="text-base uppercase">Invite your friend through email</p>
      </div>
      <div className="w-full mb-4">
        <div role="group" aria-labelledby="checkbox-group" className="flex flex-col">
          <label className="mb-1">
            <Field type="checkbox" name="reason" value="no_time" className={checkboxClasses} />
            <span>I do not have time to practice</span>
          </label>
          <label className="mb-1">
            <Field type="checkbox" name="reason" value="not_interested" className={checkboxClasses}/>
            <span>The content is not interesting to me</span>
          </label>
          <label className="mb-1">
            <Field type="checkbox" name="reason" value="too_expensive" className={checkboxClasses} />
            <span>The subscription is too expensive</span>
          </label>
          <label className="mb-1">
            <Field type="checkbox" name="reason" value="no_reason" className={checkboxClasses} />
            <span>No reason</span>
          </label>
        </div>
      </div>
      <div className="w-full">
        {touched.feedback && errors.feedback && (<p className={errorClasses}>{errors.feedback}</p>)}
        <Field name="feedback" as="textarea" placeholder="Feedback" className="form-input w-full mb-2.5 text-base text-white py-2.5 px-2.5 border min-h-[140px] border-white bg-slate resize-none placeholder-white placeholder-opacity-50" />
      </div>
      <div className="w-full flex justify-between items-end">
        <Button
          text="Cancel Subscription"
          size="small"
          classes="text-center w-fit"
          color="light"
          disabled={isSubmitting}
        />
      </div>
    </Form>
  )
}

interface CancelFormProps {
  errors?: string,
  handleSubmit: any
}

const CancelForm = withFormik<CancelFormProps, CancelValues>({
  mapPropsToValues: () => ({
    reason: '',
    feedback: '',
  }),
  validationSchema: Yup.object({
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true)
    
    props.handleSubmit(values)
  }
})(BaseForm)

export default function CancelSubscrption(props: any) {
  return (
    <div className="">
      <div className="w-full mb-24">
        <h5 className="text-base uppercase mb-1">We’re sorry to see you go</h5>
        <p className="text-md">We’d love to know how to improve.</p>
      </div>
      {!props.success ? (
        <CancelForm handleSubmit={props.userAction} />
        ) : (
        <div className="w-full mb-24">
          <p>{props.message}</p>
        </div>
        )}
    </div>
  )
}

