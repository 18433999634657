import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

export const listStyle = "flex items-baseline mx-3.5 first:ml-0 last:mr-0 relative after:content-['|'] after:absolute after:block after:right-[-15px] after:top-[-3px] after:text-grey last:after:hidden mobile:mx-2 mobile:after:right-[-10px] mobile:after:top-[-1px] mobile:after:text-m-base"
export const errorClasses = "text-xs uppercase mb-1 text-slate tracking-wider mb-1.5 ml-3"
export const formErrorClasses = "text-sm text-grey !mt-2.5 mb-1.5 pl-3"

export const storyblokFetcher = (uuid: string) => fetch(`https://api-us.storyblok.com/v2/cdn/stories/${uuid}?token=${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}&find_by=uuid`).then((res: any) => res.json())

export const componentFetcher = (query: string) => fetch(`https://api-us.storyblok.com/v2/cdn/stories/${query}?token=${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}`).then((res: any) => res.json())

export const filterFetcher = (query: string) => fetch(`https://api-us.storyblok.com/v2/cdn/stories/${query}?token=${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}&resolve_relations=filter.categories`).then((res: any) => res.json())

export const useTag = (uuid: string) => {
  const { data, error } = useSWR(
    uuid,
    storyblokFetcher,
    {
      refreshInterval: 1000 * 60 * 30,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  return {
    tag: data ? data : null,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useHeader = () => {
  const { data, error } = useSWRImmutable(
    'main-header',
    componentFetcher
  )

  return {
    header: data ? data : null,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useFooter = () => {
  const { data, error } = useSWRImmutable(
    'main-footer',
    componentFetcher
  )

  return {
    footer: data ? data : null,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useFilter = () => {
  const { data, error } = useSWRImmutable(
    'practice/main-filter',
    filterFetcher
  )

  return {
    filter: data ? data : null,
    isLoading: !error && !data,
    isError: error,
  }
}

export const createPaymentIntent = async (amount: number, currencyCode: string) => {
  const intentFields: { amount: number, currencyCode: string } = {
    amount: amount,
    currencyCode: currencyCode,
  }

  const url = '/api/chargebee/payments/intents'

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(intentFields),
  })

  return response.json()
}

export const createCustomerWithToken = async (firstName: string, lastName: string, email: string, token: string) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      token: token,
    })
  }

  const response = await fetch('/api/chargebee/customers', options)

  return response.json()
}

type SubscriptionBody = {
  customerId: string,
  itemPriceId: string,
  paymentSourceId: string,
  coupon_ids?: string | null,
}

export const createSubscription = async (customerId: string, itemPrice: string, payment: string, coupon: string | null) => {
  const body: SubscriptionBody = {
    customerId: customerId,
    itemPriceId: itemPrice,
    paymentSourceId: payment,
  }

  if (coupon) {
    body.coupon_ids = coupon
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }

  const response = await fetch('/api/chargebee/subscriptions/create', options)

  return response.json()
}

export const createSupabaseUser = async (email: string, password: string, firstName: string, lastName: string, customerId: string) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      chargebeeId: customerId,
    })
  }

  const response = await fetch('/api/supabase/auth/signup', options)

  return await response.json()
}

export const updateSupabaseAuthUserMetadata = async (firstName: string, lastName: string, customerId: string) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      chargebeeId: customerId,
    })
  }

  const response = await fetch('/api/supabase/auth/update', options)

  return await response.json()
}

export const createSupabaseMember = async (email: string, firstName: string, lastName: string, customerId: string) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      firstName: firstName,
      lastName: lastName,
      chargebeeId: customerId,
    })
  }

  const response = await fetch('/api/supabase/members/create', options)

  return await response.json()
}

export const createSupabaseSubscription = async (userId: string, subscriptionId: string, status: string) => { 
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId: userId,
      subscriptionId: subscriptionId,
      status: status,
    })
  }

  const response = await fetch('/api/supabase/subscriptions', options)

  return await response.json()
}

export const createMailerliteMember = async (email: string, firstName: string, lastName: string, newsletter: boolean) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      firstName: firstName,
      lastName: lastName,
      newsletter: newsletter,
    })
  }

  const response = await fetch('/api/mailerlite/members/subscribe', options)

  return await response.json()
}

export const createUserFavourite = async (userId: string, id: number, uuid: string, slug: string ) => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: userId,
        practiceId: id,
        practiceUuid: uuid,
        practiceSlug: slug
      })
    }

    const response = await fetch('/api/supabase/favourites', options)

    return await response.json()
}

export const deleteUserFavourite = async (userId: string, id: string) => {
    const options = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        practiceId: id,
      })
    }

    const response = await fetch(`/api/supabase/favourites/${userId}`, options)

    return await response.json()
}

// gets user charegbee subscriptions including subscription, cards, and customer
export const getUserSubscriptions = async (userId: string) => {
  // get supabse user
  const userOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: userId })
  }

  const data = await fetch('/api/supabase/members', userOptions)

  const user = await data.json()

  if (!user) {
    return null
  }

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const response = await fetch(`/api/chargebee/subscriptions/${user.chargebee_id}`, options)

  return response.json()
}

export const cancelUserSubscriptions = async (subscriptionId: string) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subscriptionId: subscriptionId,
    })
  }

  const response = await fetch(`/api/chargebee/subscriptions/cancel`, options)

  return response.json()
}

export const verifyCoupon = async (couponId: string) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const response = await fetch(`/api/chargebee/coupons/${couponId}`, options)

  return response.json()
}

export const verifyAuthEmail = async (email: string) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
    })
  }

  const response = await fetch('/api/supabase/auth/verify', options)

  return await response.json()
}

export const verifyCustomerEmail = async (email: string) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }

  const response = await fetch(`/api/chargebee/customers/${email}`, options)

  return await response.json()
}
