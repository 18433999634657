import { storyblokEditable } from "@storyblok/react"
import { render } from "storyblok-rich-text-react-renderer"
import classNames from "classnames"

import { StoryblokData } from "../services/types"

export default function Copy({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-3 flex justify-center"
      {...storyblokEditable(blok)}
    >
      <div
        className={
          classNames( {
            'prose max-w-[600px]': true,
            'max-w-none': blok.width === 'full',
            'mx-auto': blok.width === 'inline',
            'text-base mobile:text-m-base': blok.textSize === 'small',
            'text-md mobile:text-base': blok.textSize === 'large',
            'text-center': blok.align === 'center'
          })
        }
      >
        {render(blok.text)}
      </div>
    </section>
  )
}
