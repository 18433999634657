import { useDispatch, useSelector } from 'react-redux'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { setFilterShow } from '../services/state'
import classNames from 'classnames'

export default function FilterButton() {
  const dispatch = useDispatch()

  const show = useSelector((state: any) => {
    return state.filter.show
  })

  const handleClick = () => {
    dispatch(setFilterShow(!show))
  }

  return (
    <button
      className={
        classNames({
          'rounded bg-slate border border-slate flex justify-between text-white px-1 py-0.5 h-[22px] min-w-[144px] mobile:min-w-[unset] mobile:w-full': true,
        })
      }
      onClick={handleClick}
    >
      <span className="uppercase text-base mobile:text-m-base">Filter</span>
      <span>
        {show ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
      </span>
    </button>
  )
}
