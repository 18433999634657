import { useRouter } from 'next/router'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import { errorClasses } from '@/services/utils'

import Button from '@/components/base/Button'
import SubscriptionItem from '@/components/forms/SubscriptionItem'

interface SubscriptionValues {
  membership: string,
}

type OtherProps = {
  items: any
  userAction: any
}


const BaseForm = (props: OtherProps & FormikProps<SubscriptionValues>) => {
  const { items, values, touched, errors, isSubmitting } = props
  
  return (
    <Form className="flex flex-col">
      {touched.membership && errors.membership && <div className={errorClasses}>{errors.membership}</div>}
      {items && items.length > 0 && items.map((item: any) => (
        <SubscriptionItem
          key={item.item_price.id}
          id={item.item_price.id}
          name={item.item_price.name}
          price={item.item_price.price}
          active={values.membership === item.item_price.id}
          type="field"
        />
      ))}
      <div className="text-base mb-5 px-1">
        <p>You won’t be charged until after your 10 day free trial ends. Price is in AUD.</p>
      </div>
      <div className="w-full flex justify-between items-end">
        <Button
          text="Create Account"
          color="dark"
          size="small"
          classes="text-center w-fit"
          style="button"
          disabled={isSubmitting}
        />
      </div>
    </Form>
  )
}

interface SubscriptionFormProps {
  items: any,
  handleSubmit: any,
  membership: string,
  errors?: string,
}

const SubscriptionDetails = withFormik<SubscriptionFormProps, SubscriptionValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    membership: props.membership,
  }),
  validationSchema: Yup.object({
    membership: Yup.string().required('Please select a membership'),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    console.log(props)
    console.log(values)

    setSubmitting(true)

    props.handleSubmit(values)
  }
})(BaseForm)

export default function CreateSubscription(props: any, values: SubscriptionFormProps) {
  let { membership } = values
  const router = useRouter()

  if (router.query?.plan) {
    const item = props.items.find((item: any) => item.item_price.name.toLowerCase() === router.query.plan)

    if (item) {
      membership = item.item_price.id
    }
  }

  return (
    <div className="">
      <SubscriptionDetails
        items={props.items}
        handleSubmit={props.userAction}
        membership={membership}
      />
    </div>
  )
}
