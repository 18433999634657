import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { storyblokEditable } from "@storyblok/react"
import { ArrowPathIcon, ArrowSmallDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import { StoryblokData } from "../services/types"

import PracticeItem from '@/components/base/PracticeItem'

const MoreButton = ({ loading, handleClick }: any) => {
  return (
    <button
      className={
        classNames({
          'rounded bg-cream border border-slate flex justify-between text-slate px-1 py-0.5 h-[22px] min-w-[144px] mobile:min-w-[unset] mobile:w-full': true,
        })
      }
      onClick={handleClick}
    >
      <span className="uppercase text-base mobile:text-m-base">{loading ? 'Loading' : 'Show More'}</span>
      <span>
        {loading ? (<ArrowPathIcon className="w-4 h-4 animate-spin" />) : (<ArrowSmallDownIcon className="w-4 h-4" />)}
      </span>
    </button>
  )
}

export default function FilterResults({ blok }: StoryblokData) {
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [results, setResults] = useState<object[]>([])
  // pagination
  const [page, setPage] = useState<number | null>(1)
  const [more, setMore] = useState(true)
  const perPage = 24

  const router = useRouter()

  const fetchResults = async (forTags: string, currentPage: number) => {
    if (!currentPage) return

    const options = {
      method: 'POST',
      body: JSON.stringify({ page: currentPage, perPage: perPage, tags: forTags })
    }

    const response = await fetch(`/api/practice/filter?tags=${forTags}`, options)
      
    return await response.json()
  }

  useEffect(() => {
    if (!router.query?.tags || (page === undefined || perPage === undefined)) {
      return
    }

    setPage(1)
    setMore(true)
    setLoaded(false)

    const fetchItems = async () => {
      setLoading(true)

      const data: any = await fetchResults(router.query?.tags as string, 1)

      // no more results to fetch
      if (data && data.stories.length < perPage) {
        setMore(false)
      }

      setResults(data.stories)

      setTimeout(() => {
        // set initial loaded 
        setLoaded(true)

        // updates per request / update
        setLoading(false)
      }, 100)
    }

    fetchItems()
  }, [router.query.tags])

  const handleClick = () => {
    const update = page as number + 1
    setPage(update)

    const fetchMore = async () => {
      setLoading(true)

      const data: any = await fetchResults(router.query?.tags as string, update as number)

      // no more results to fetch
      if (data && data.stories.length < perPage) {
        setMore(false)
      }

      setResults(results.concat(data.stories))

      setTimeout(() => {
        // set initial loaded 
        setLoaded(true)

        // updates per request / update
        setLoading(false)
      }, 100)
    }

    if (more) {
      fetchMore()
    }
  }

  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 mobile:px-1"
      {...storyblokEditable(blok)}
    >
      {loading && <p className="absolute">Loading...</p>}
      {(loaded && results && results.length > 0) && (
        <ul className="flex flex-wrap">
          {results.map((practice: any) => (
            <PracticeItem
              key={practice.id}
              practice={practice}
              width="25"
              classes="mb-16"
            />
          ))}
        </ul>
      )}
      {more && loaded && (
        <div className="flex justify-center mt-6 mb-10">
          <MoreButton loading={loading} handleClick={handleClick} />
        </div>
      )}
      {!loading && loaded && results && results.length === 0 && (
        <p>No results found.</p>
      )}
    </section>
  )
}
