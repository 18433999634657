import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import ImageBlock from "@/components/ImageBlock"
import SignIn from '@/components/forms/SignIn'
import CreateAccount from '@/components/forms/CreateAccount'
import ResetPassword from '@/components/forms/ResetPassword'
import NewPassword from '@/components/forms/NewPassword'

export default function HeroForm({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 flex mobile:px-2.5 tablet:flex-col-reverse tablet:items-center tablet:mt-16"
      {...storyblokEditable(blok)}
    >
      <div className="w-1/2 px-1.5 relative max-h-[calc(100vh-80px)] overflow-hidden mobile:hidden tablet:w-full tablet:mt-16">
        {blok.hero &&
          <ImageBlock image={blok.hero} />
        }
        <h1 className="text-base text-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] uppercase z-1">{blok.title}</h1>
      </div>
      <div className="w-1/2 px-1.5 flex justify-center items-center mobile:w-full mobile:px-0 tablet:w-full mobile:min-h-[50vh]">
        {blok.form === 'accountLogin' &&
          <SignIn />
        }
        {blok.form === 'accountCreate' &&
          <CreateAccount />
        }
        {blok.form === 'resetPassword' &&
          <ResetPassword />
        }
        {blok.form === 'newPassword' &&
          <NewPassword />
        }
      </div>
    </section>
  )
}
