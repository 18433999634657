import { useState, useEffect, ReactNode } from 'react'
import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'

import PracticeItem from '@/components/base/PracticeItem'

type PopupProps = {
  active: boolean,
  close: () => void,
  hideClose?: boolean,
  children: ReactNode,
}

// active and close are required props and come from parent component
const VideoEndPopup = ({ active, close, hideClose = false, children }: PopupProps) => {
  const [show, setShow] = useState(false)
  
  useEffect(() => {
    if (active) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [active])

  return (
    <aside
      className={
        classNames({
          'bg-[rgba(255,255,255,0.95)] fixed w-full h-full top-0 left-0 z-50 flex items-center justify-center transition-all duration-500 opacity-0': true,
          'opacity-100': show,
          'hidden': !active,
        })
      }
    >
      <div
        className={
          classNames({
            'w-full text-slate p-1.5 relative h-screen mobile:h-min mobile:p-0': true,
          })
        }>
        {!hideClose && (
          <button
            onClick={close}
            className={
              classNames('absolute top-0 right-0 mt-4 mr-4 text-slate z-30 mobile:mr-2.5 mobile:mt-2.5')
            }
          >
            <XMarkIcon className="w-8 h-8 mobile:w-5 mobile:h-5" />
            <span className="sr-only">Close</span>
          </button>
        )}
        {children}
      </div>
    </aside>
  )
}

export default function PracticeRecommended({ title, byline, practices, active, handleClose }: PopupProps & any) {
  if (!practices) {
    return null
  }

  return (
    <>
      <VideoEndPopup
        active={active}
        close={() => handleClose()}
      >
        <div className="relative h-full flex w-full mobile:flex-col mobile:overflow-auto mobile:h-[calc(var(--vh)*100)]">
          <header className="mb-4 px-2.5 mobile:w-full mobile:py-3 mobile:flex mobile:items-center mobile:justify-center mobile:flex-col mobile:min-h-[calc(var(--vh)*50)]">
            <p className="text-base mobile:text-m-base">{byline}</p>
            <h2 className="text-lg mt-2 mobile:text-m-base">{title}</h2>
          </header>
          <div className="flex flex-col absolute top-[50%] translate-y-[-50%] w-full mobile:relative mobile:top-0 mobile:translate-y-0 mobile:pb-6">
            <ul className="flex mobile:flex-wrap">
              {practices && practices.map((item: any) => (
                <PracticeItem
                  key={item.id}
                  practice={item}
                  width={'25'}
                  classes="px-2.5"
                />
              ))}
            </ul>
          </div>
        </div>
      </VideoEndPopup>
    </>
  )
}
