import { StoryblokData } from '../services/types'
import { useUser } from '@supabase/auth-helpers-react'

import Button from './base/Button'

export default function MenuLink({ blok, size, classes }: StoryblokData) {
  const user = useUser()

  const linkUrl = blok.link.linktype === 'story' ? `/${blok.link.cached_url}` : blok.link.url

  let buttonClasses = blok.hideOnDesktop && blok.hideOnDesktop !== undefined ? `${classes} mobile-up:hidden` : classes

  buttonClasses = blok.loggedInOnly ? (!user && blok.loggedInOnly ? `${buttonClasses} hidden` : buttonClasses) : buttonClasses

  return (
    <Button
      text={blok.title}
      color={blok.colorStyle}
      style={blok.linkStyle}
      size={size}
      link={true}
      linkUrl={linkUrl}
      classes={buttonClasses}
    />
  )
}
