import { storyblokEditable } from "@storyblok/react"
import { render } from 'storyblok-rich-text-react-renderer'
import classNames from "classnames"

import { StoryblokData } from "../services/types"

import ImageBlock from "./ImageBlock"

export default function HeroText({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      {...storyblokEditable(blok)}
      className={
        classNames({
          "w-full px-1.5 py-1 flex mobile:flex-wrap": true,
          'flex-row-reverse': blok.first === 'text'
        })
      }
    >
      <div className="w-1/2 px-1.5 mobile:w-full">
        {blok.desktop && blok.desktop.id && <ImageBlock image={blok.desktop} />}
      </div>
      <div className="w-1/2 px-1.5 flex flex-col justify-center relative mobile:w-full">
        <div className="w-full prose text-lg mobile:text-m-lg mobile:my-2.5">
          {render(blok.text)}
        </div>
        {blok.includeByline &&
          <div
            className={
              classNames({
                'text-base w-full': true,
                'absolute top-0 left-0': blok.bylinePosition === 'top',
                'absolute bottom-0 left-0': blok.bylinePosition === 'bottom',
                'mt-2': blok.bylinePosition === 'inline',
              })
            }
          >
            {blok.bylineText}
          </div>
        }
      </div>
    </section>
  )
}
