import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'

import { errorClasses, formErrorClasses } from '@/services/utils'

import Button from '@/components/base/Button'

interface SignInWithEmailValues {
  email: string,
}

type OtherProps = {
  userAction: any,
  serverErrors?: any,
}

const BaseForm = (props: OtherProps & FormikProps<SignInWithEmailValues>) => {
  const { touched, errors, isSubmitting, serverErrors } = props

  const fieldClasses = 'form-input text-base w-full mb-2.5 pt-2 pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'

  return (
    <Form
      className={
        classNames({
          'flex flex-col': true,
          'opacity-40': isSubmitting,
        })
      }
    >
      <div className="w-full">
        {serverErrors && serverErrors.length > 0 && (
          <div className={formErrorClasses}>There was an issue with your email: <span className={errorClasses + ' !ml-1'}>{serverErrors.join(', ')}</span></div>
        )}
      </div>
      <div className="w-full">
        {touched.email && errors.email && (<p className={errorClasses}>{errors.email}</p>)}
        <Field name="email" type="email" placeholder="Email" className={fieldClasses} />
      </div>
      <div className="w-full flex justify-between items-end mobile:flex-col mobile:justify-start mobile:items-start">
        <Button
          text="Sign In"
          size="small"
          classes="text-center w-fit px-12 min-w-[160px] mobile:w-full"
          color="dark"
          disabled={isSubmitting}
        />
        {isSubmitting && (
            <p className="text-sm uppercase mb-1 text-slate">Loading...</p>
        )}
      </div>
    </Form>
  )
}

interface EmailFormProps {
  errors?: string,
  handleSubmit: any,
  serverErrors?: any,
}

const SignInForm = withFormik<EmailFormProps, SignInWithEmailValues>({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)

    props.handleSubmit(values)
  }
})(BaseForm)

export default function SignInWithEmail(props: any) {
  return (
    <div className="">
      <SignInForm
        handleSubmit={props.userAction}
        serverErrors={props.serverErrors}
      />
    </div>
  )
}
