import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import Button from "./base/Button"
import BlogItem from "./BlogItem"

export default function BlogRow({ blok }: StoryblokData) {
  const buttonStyles = 'text-lg px-10 !pt-2.5 !pb-2 !rounded-[50px] mobile:!text-m-lg mobile:w-full mobile:text-center'
  return (
    <section
      className="my-8 px-1.5 mobile:my-6"
      {...storyblokEditable(blok)}
    >
      {blok.includeLink &&
        <div className="mb-16 flex justify-center mobile:mb-10">
          <Button
            text={blok.linkText}
            link={true}
            linkUrl={blok.link}
            size="large"
            color="light"
            classes={buttonStyles}
          />
        </div>
      }
      <ul className="flex mobile:flex-wrap">
        {blok.articles && blok.articles.map((article: any) => (
          <BlogItem
            key={article.content._uid}
            content={article.content}
            slug={article.slug}
          />
        ))}
      </ul>
    </section>
  )
}
