import { useState } from 'react'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { useUser } from '@supabase/auth-helpers-react'

import classNames from 'classnames'

import Button from '@/components/base/Button'

interface ResetValues {
  password: string,
}

type OtherProps = {
  userAction: any,
  serverErrors?: any,
  loading: boolean,
}


const ResetStep = (props: any) => {
  const { title, step, loaded, children } = props

  return (
    <div className={classNames('flex flex-col w-full opacity-0 transition-opacity duration-300 delay-200', {
      'hidden': step !== props.step,
      'opacity-100': loaded
    })}>
      <header className="my-2.5 relative">
        <h5 className="text-base text-center mobile:text-m-base">{title}</h5>
      </header>

      {children}
    </div>
  )
}

const BaseForm = (props: OtherProps & FormikProps<ResetValues>) => {
  const { touched, errors, isSubmitting, serverErrors, loading } = props

  const fieldClasses = 'form-input text-base w-full mb-2.5 pt-2 pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'
  const errorClasses = 'text-xs uppercase mb-1 text-slate tracking-wider mb-1.5 ml-3'

  return (
    <Form
      className={
        classNames({
          'flex flex-col': true,
          'opacity-40': isSubmitting,
        })
      }
    >
      <div className="w-full">
        {serverErrors && serverErrors.length > 0 && (
          <div className="text-sm text-grey !mt-2.5 mb-1.5 pl-3">There was an issue with your sign in: <span className={errorClasses + ' !ml-1'}>{serverErrors.join(', ')}</span></div>
        )}
      </div>
      <div className="w-full">
        {touched.password && errors.password && (<p className={errorClasses}>{errors.password}</p>)}
        <Field name="password" type="password" placeholder="Password" className={fieldClasses} />
      </div>
      <div className="w-full flex justify-between items-end">
        <Button
          text="Save Password"
          size="small"
          classes="text-center w-fit px-12 min-w-[160px] mobile:w-full"
          color="dark"
          disabled={isSubmitting || loading}
        />
      </div>
    </Form>
  )
}

interface ResetFormProps {
  errors?: string,
  handleSubmit: any,
  loading: boolean,
  serverErrors?: any,
}

const ResetForm = withFormik<ResetFormProps, ResetValues>({
  mapPropsToValues: () => ({
    password: '',
  }),
  validationSchema: Yup.object({
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is Required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)

    props.handleSubmit(values)
  }
})(BaseForm)

export default function ResetPassword(props: any) {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const [formLoaded, setFormLoaded] = useState(true)
  const [resetSentLoaded, setResetSentLoaded] = useState(false)
  
  const supabase = useSupabaseClient()
  const user = useUser()

  const handlePasswordNew = async (values: { password: string }) => {
    setLoading(true)

    const { data } = await supabase.auth.updateUser({ password: values.password })

    if (data) {
      setStep(2)
      setFormLoaded(false)
      setResetSentLoaded(true)
    }
  }

  return (
    <div className="w-[66%] max-w-lg mobile:w-full mobile:max-w-none tablet:w-full tablet:max-w-none">
      {user ? (
        <>
          <ResetStep title="Create New Password" step={1} loaded={formLoaded}>
            <ResetForm
              handleSubmit={handlePasswordNew}
              serverErrors={props.serverErrors}
              loading={loading}
            />
          </ResetStep>
          <ResetStep title="" step={2} loaded={resetSentLoaded}>
            <div className="text-center flex flex-col items-center">
              <p className="mb-6 text-slate text-base">Your new password has been saved.</p>
            </div>
          </ResetStep>
        </>
      ) : (
        <div className="text-center flex flex-col items-center">
          <p className="mb-6 text-slate text-base">You must be signed in to change your password.</p>
        </div>
      )}
    </div>
  )
}
