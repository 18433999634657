import classNames from 'classnames'

const LoadedTag = ({ tag, style, color }: any) => {
  return (
    <p
      className={
        classNames({
          'transition-all duration-300 ease-in-out whitespace-nowrap': true,
          'text-base mobile:text-m-base': style === 'inline' || style === undefined,
          'text-xs uppercase': style === 'small',
          'text-grey': color === 'grey',
          'text-white mobile:text-grey': color === 'white',
          'hidden': !tag?.name,
        })
      }
    >
      {tag?.name}
    </p>
  )
}

export default LoadedTag
