import classNames from "classnames"

import { useTag } from '@/services/utils'

export default function FilterTag({ uuid, active, handleClick }: any) {
  const { tag, isLoading, isError } = useTag(uuid)

  if (isError) {
    return (<p className="hidden">Not found</p>)
  }

  if (tag === null || !tag?.story) {
    return (<></>)
  }

  return (
    <button
      className={
        classNames({
          'px-2 pt-2.5 pb-2 rounded-sm bg-white w-full text-base text-left': true,
          '!bg-slate text-white': tag && active.includes(tag.story.slug),
        })
      }
      onClick={handleClick}
      data-uuid={uuid}
      data-slug={tag ? tag.story.slug : ''}
    >
      {!isError && 
        <span
          className={
            classNames({
              'transition-all duration-300 ease-in-out whitespace-nowrap pointer-events-none': true,
              'blur-[4px]': isLoading,
            })
          }
        >
          {isLoading && tag === null ? 'Loading' : tag.story.name}
        </span>
      }
    </button>
  )
}
