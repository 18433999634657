import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import { setFilterResults } from '../services/state'

import PracticeItem from '@/components/base/PracticeItem'

export default function FilterResults({ blok }: StoryblokData) {
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState<any>(null)
  const [term, setTerm] = useState<any>(null)

  const dispatch = useDispatch()

  const router = useRouter()

  useEffect(() => {
    if (!term || term.length === 0) {
      return
    }

    const fetchResults = async () => {
      const response = await fetch(`/api/practice/search?for=${term}`)
      const data = await response.json()
      setResults(data)

      dispatch(setFilterResults(data))

      setTimeout(() => {
        setLoading(false)
      }, 600)
    }

    fetchResults()
  }, [term])

  useEffect(() => {
    if (router.query.for) {
      setTerm(router.query.for)
    }
  }, [router.query.for])

  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 mobile:px-1"
      {...storyblokEditable(blok)}
    >
      {loading && <p>Loading...</p>}
      {!loading && results && results.length > 0 && (
        <ul className="flex flex-wrap">
          {results.map((practice: any) => (
            <PracticeItem
              key={practice.id}
              practice={practice}
              width="25"
              classes="mb-16"
            />
          ))}
        </ul>
      )}
      {!loading && results && results.length === 0 && (
        <p>No results found.</p>
      )}
    </section>
  )
}
