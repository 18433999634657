import Link from 'next/link'
import classNames from "classnames"

interface ButtonComponent {
  text: string,
  color: 'light' | 'dark',
  size: 'small' | 'regular' | 'large' | 'xl',
  style?: string,
  link?: boolean,
  linkUrl?: string | undefined,
  externalLink?: boolean,
  clickEvent?: any,
  disabled?: boolean,
  classes?: string
}

export default function Button({ text, color, size, style, link, linkUrl, externalLink, clickEvent, disabled, classes }: ButtonComponent) {
  const baseClasses = style === 'text'
    ? 'mobile:text-m-base'
    : 'w-full appearance-none inline-block w-fit text-base text-left uppercase leading-none rounded-[20px] border border-slate self-start disabled:opacity-50 disabled:cursor-not-allowed mobile:text-m-base'
  const url = linkUrl ? linkUrl : '#'

  if (link) {
    if (externalLink) {
      return  (
        <a
          href={url}
          className={
            classNames(
              size === 'small' ? 'pb-1 pt-1 px-6' : 'pb-2.5 pt-2.5 px-8',
              style === 'text' ? '!p-0' : '',
              {
                'bg-offwhite text-slate hover:bg-slate hover:text-white': color === 'light',
                'bg-slate text-white hover:bg-offwhite hover:text-slate': color === 'dark',
                [baseClasses]: true,
              },
              classes ? [classes] : null,
            )
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          {text}
        </a>
      )
    } else {
      return  (
        <Link
          href={url}
          className={
            classNames(
              size === 'small' ? 'pb-1 pt-1 px-6' : 'pb-2.5 pt-2.5 px-8',
              style === 'text' ? '!p-0' : '',
              {
                'bg-offwhite text-slate hover:bg-slate hover:text-white': color === 'light',
                'bg-slate text-white hover:bg-offwhite hover:text-slate': color === 'dark',
                [baseClasses]: true,
              },
              classes ? [classes] : null,
            )
          }
        >
          {text}
        </Link>
      )
    }
  } else {
    return (
      <>
        {clickEvent ?
          <button
            className={
              classNames(
                size === 'small' ? 'pb-1 pt-1 px-6' : 'pb-2.5 pt-2.5 px-8',
                color === 'light' ? 'bg-offwhite text-slate hover:bg-slate hover:text-white' : 'bg-slate text-white hover:bg-offwhite hover:text-slate',
                { [baseClasses]: true },
                classes ? [classes] : null,
              )
            }
            disabled={disabled}
            onClick={clickEvent}
          >
            {text}
          </button>
        :
          <button
            className={
              classNames(
                size === 'small' ? 'pb-1 pt-1 px-3' : 'pb-2.5 pt-2.5 px-5',
                color === 'light' ? 'bg-offwhite text-slate hover:bg-slate hover:text-white' : 'bg-slate text-white hover:bg-offwhite hover:text-slate',
                { [baseClasses]: true, },
                classes ? [classes] : null,
              )
            }
            disabled={disabled}
            type="submit"
          >
            {text}
          </button>
        }
    </>
    )
  }
}
