import { storyblokEditable, StoryblokComponent, SbBlokData } from '@storyblok/react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import Layout from './Layout'

import { StoryblokData } from '@/services/types'

export default function Page({ blok }: StoryblokData) {
  const loaded = useSelector((state: any) => state.app.loaded)
  const loading = useSelector((state: any) => state.app.loading)
  const route = useSelector((state: any) => state.app.route)

  return (
    <Layout>
      <main
        {...storyblokEditable(blok)}
        key={blok._uid}
        className={
          classNames({
            'antialiased min-h-screen pt-16 mobile:pt-28': true,
            'pt-72': route === '/',
            'opacity-0': loading,
            'opacity-100': loaded
          })
        }
      >
        {blok.content && blok.content.map((child: any) => {
          return <StoryblokComponent blok={child} key={child._uid}  />
        })}
      </main>
    </Layout>
  )
}
