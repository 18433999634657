import { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { StoryblokData } from "@/services/types"
import classNames from "classnames"

type ImageBlockProps = {
  image: StoryblokData,
  hero?: boolean,
  single?: boolean,
  classes?: string,
  cover?: boolean,
}

export default function ImageBlock({ image, hero, single, classes, cover }: ImageBlockProps) {
  const [sizes, setSizes] = useState<any>(null)
  const [isSvg, setIsSvg] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(1)
  
  const imageRef = useRef<any>(null)

  useEffect(() => {
    if (image.filename === null || image.filename === '') {
      return
    }

    const fileType = image.filename.split('.')[image.filename.split('.').length - 1]

    const width = image.filename.split('/')[image.filename.split('/').length - 3].split('x')[0]
    const height = image.filename.split('/')[image.filename.split('/').length - 3].split('x')[1]

    // calculate aspect ratio
    const ratio = width / height

    setIsSvg(fileType === 'svg')

    setAspectRatio(ratio)
  }, [image])

  // Set sizes of image for responsive images
  useEffect(() => {
    if (!image) {
      return
    }

    const responsiveImages = {
      xsmall: `${image.filename}/m/400x0/`,
      small: `${image.filename}/m/600x0/`,
      medium: `${image.filename}/m/1024x0/`,
      large: `${image.filename}/m/1400x0/`,
      xlarge: `${image.filename}/m/1920x0/`
    }

    setSizes(responsiveImages)
  }, [image])

  return (
    <>
      {sizes && sizes !== null && window &&
      <div
        className={
          classNames(
            classes ? classes : '',
            {
              'bg-[rgba(0,0,0,0)] w-full': true,
            }
          )
        }
      >
      <div
        className={
          classNames({
            'pb-[48.5%]': hero && single,
            'aspect-w-16 aspect-h-9': hero && !single,
            '!aspect-w-9 !aspect-h-16': cover,
            'aspect-w-4 aspect-h-3': !hero && aspectRatio > 1.1 && aspectRatio < 1.7,
            'aspect-w-2 aspect-h-1': !hero && aspectRatio >= 1.7,
            'aspect-w-3 aspect-h-4': !hero && aspectRatio < 0.9,
            'aspect-w-1 aspect-h-1': !hero && aspectRatio >= 0.9 && aspectRatio <= 1.1
          })
        }
      >
        <img
          ref={imageRef}
          loading="lazy"
          src={sizes.medium}
          srcSet={`${sizes.xsmall} 400w, ${sizes.small} 600w, ${sizes.medium} 1024w, ${sizes.large} 1400w, ${sizes.xlarge} 1920w`}
          alt={image.alt}
          className={classNames({
            'opacity-100 absolute top-0 left-0 object-cover object-center transition-opacity duration-300': true,
            'w-full h-full object-cover': hero && single
          })}
        />
      </div>
      </div>
      }
    </>
  )
}

