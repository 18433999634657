import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { setPopup, setMovement } from '@/services/state'
import { PlayIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

import ImageBlock from "./../ImageBlock"

export default function MovementItem({ movement }: any) {
  const dispatch = useDispatch()

  const handleClick = (evt: any) => {
    evt.preventDefault()
    
    dispatch(setMovement(movement))
    dispatch(setPopup(true))
  }

  return (
    <li
      className={
        classNames({
          'px-1.5 w-1/5 mb-10 mobile:w-full mobile:flex mobile:mb-2.5 tablet:w-1/3': true,
        })
      }
    >
      <Link
        href={`#{movement.slug}`}
        onClick={handleClick}
        className="block transition-opacity duration-200 relative mobile:block mobile:w-1/3 [&_svg]:hidden [&_svg]:hover:block"
      >
        {movement.content.thumbnail && <ImageBlock image={movement.content.thumbnail} hero={true} />}
        <PlayIcon className="w-6 h-6 absolute bottom-2.5 right-3 text-white rounded-none mobile:w-4 mobile:h-4" />
      </Link>
      <div className="mt-2.5 mobile:flex mobile:items-center mobile:px-2.5">
        <h5 className="text-md mobile:text-base">
          <Link 
            href={`#{movement.slug}`}
            onClick={handleClick}
          >
            {movement.name}
          </Link>
        </h5>
        <p className="text-base text-grey">{movement.content.duration}</p>
      </div>
    </li>
  )
}
