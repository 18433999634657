import { render } from 'storyblok-rich-text-react-renderer'
import classNames from 'classnames'

import { StoryblokData } from "../services/types"

import ImageBlock from "./ImageBlock"

type DetailProps = {
  description: string,
  image: StoryblokData,
  group: string,
  align: string,
  width: string,
  show: boolean,
}

export default function HeadingListContent({ description, image, group, align, width, show }: DetailProps) {
  return (
    <aside
      className={
        classNames({
          'flex flex-col absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-200 mobile:px-0.5 mobile:pb-2.5 mobile:relative mobile:flex-col-reverse mobile:w-full': true,
          'justify-center': group === 'center',
          'justify-start': group === 'top',
          'justify-end': group === 'bottom',
          'items-start': align === 'left',
          'items-center': align === 'center',
          'items-end': align === 'right',
          '!opacity-100 !duration-500': show,
        })
      }
    >
      <div
        className={
          classNames({
            'max-w-[600px] lg:min-w-[220px] mobile:max-w-[unset] mobile:!w-full mobile:h-full mobile:flex mobile:justify-start mobile:items-start': true,
            'w-1/2': width === '50',
            'lg:w-[40%] md:w-1/2': width === '40',
            'lg:w-1/3 md:w-1/2': width === '33',
            'lg:w-1/4 md:w-1/2': width === '25',
          })
        }
      >
        <div className="flex w-full mobile:max-w-[144px]">
          <ImageBlock image={image} />
        </div>
    </div>
      <div
        className={
          classNames({
            'lg:min-w-[220px] mobile:w-full mobile:h-full mobile:flex mobile:flex-col': true,
            'w-1/2': width === '50',
            'lg:w-[40%] md:w-1/2': width === '40',
            'lg:w-1/3 md:w-1/2': width === '33',
            'lg:w-1/4 md:w-1/2': width === '25',
            'mobile:justify-center': group === 'center',
            'mobile:justify-start': group === 'top',
            'mobile:justify-end': group === 'bottom',
            'mobile:items-start': align === 'left',
            'mobile:items-center': align === 'center',
            'mobile:items-end': align === 'right',
          })
        }
      >
        <div className="prose text-base my-2.5 mobile:text-m-base mobile:mt-1 mobile:mb-3 max-w-none">{render(description)}</div>
      </div>
    </aside>
  )
}
