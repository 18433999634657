import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import Button from "./base/Button"

export default function MembershipCard({ blok }: StoryblokData) {
  return (
    <li
      className="w-1/5 m-2.5 flex self-start bg-cream border border-grey rounded-md drop-shadow mobile:w-full mobile:mx-0 tablet:w-1/3 tablet:m-2"
      {...storyblokEditable(blok)}
    >
      <div className="w-full aspect-w-4 aspect-h-3">
        <div className="w-full flex flex-col items-center justify-between px-5 py-6">
          <div className="text-center">
            <p className="text-base uppercase">{blok.title}</p>
            <h5 className="text-md my-3">{blok.price}</h5>
          </div>
          <div className="text-center">
            {blok.note && blok.note !== '' &&
              <p className="text-base text-center mb-4">{blok.note}</p>
            }
            <Button
              text={blok.title}
              link={true}
              linkUrl={blok.link.url}
              size="small"
              color="light"
              classes="bg-cream mobile:px-10 mobile:min-w-[180px] mobile:text-center"
            />
          </div>
        </div>
      </div>
    </li>
  )
}
