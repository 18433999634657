import { useRef } from 'react'
import Link from 'next/link'
import { PlayIcon } from '@heroicons/react/24/solid'

import classNames from 'classnames'

import Tag from '@/components/base/Tag'
import LoadedTag from '@/components/base/LoadedTag'

import { listStyle } from '@/services/utils'

export default function GalleryItem({ practice }: any) {
  const url = `/practice/${practice.slug}`
  const image = practice.content.thumbnail

  const { style, tone, body } = practice.content

  const imageRef = useRef<HTMLImageElement>(null)

  const sizes = {
    xsmall: `${image.filename}/m/240x0/`,
    small: `${image.filename}/m/400x0/`,
    medium: `${image.filename}/m/720x0/`,
  }

  const renderTag = (tag: any, style: string, color: string) => {
    return typeof tag === 'string' ? <Tag uuid={tag} style={style} color={color} /> : (<LoadedTag tag={tag} style={style} color={color} />)
  }

  return (
    <li className="glide__slide w-1/3 max-w-1/3">
      <div className="flex items-baseline justify-start mb-1.5">
        <h5 className="text-md mr-1.5 mobile:text-base">
          <Link href={url}>
            {practice.name}
          </Link>
        </h5>
        {practice.content.tag && (
          renderTag(practice.content.tag, 'small', 'slate')
        )}
      </div>
      <Link
        href={url}
        className="block transition-opacity duration-200 relative [&_svg]:hidden [&_svg]:hover:block"
      >
        <div
          className={
            classNames({
              '!aspect-w-16 !aspect-h-9': true,
            })
          }
        >
          <img
            ref={imageRef}
            loading="lazy"
            src={sizes.small}
            srcSet={`${sizes.xsmall} 400w, ${sizes.small} 600w, ${sizes.medium} 1024w`}
            alt={image.alt}
            className={classNames({
              'opacity-100 absolute top-0 left-0 object-cover transition-opacity duration-300': true,
              //'opacity-100': loaded,
            })}
          />
        </div>
        <PlayIcon className="w-6 h-6 absolute bottom-3 right-3 text-white rounded-none mobile:w-4 mobile:h-4" />
      </Link>
      <div className="flex items-baseline mt-1">
        <p className="text-md mobile:text-m-base mr-3 whitespace-nowrap mobile:mr-1.5">{practice.content.duration}</p>
          <ul className="flex mx-4 overflow-hidden mobile:mx-2.5">
            {style && style.length > 0 &&
              <li
                className={listStyle}
              >
                {style.map((tag: any, index: number) => (
                  <span key={`styleTag${index}`} className="flex">
                    {renderTag(tag, 'inline', 'grey')}
                    {style.length > 1 && index < style.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
                  </span>
                ))}
              </li>
            }
            {tone && tone.length > 0 &&
              <li
                className={listStyle}
              >
                {tone.map((tag: any, index: number) => (
                  <span key={`toneTag${index}`} className="flex">
                    {renderTag(tag, 'inline', 'grey')}
                    {tone.length > 1 && index < tone.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
                  </span>
                ))}
              </li>
            }
            {body && body.length > 0 &&
              <li
                className={listStyle}
              >
                {body.map((tag: any, index: number) => (
                  <span key={`bodyTag${index}`} className="flex">
                    {renderTag(tag, 'inline', 'grey')}
                    {body.length > 1 && index < body.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
                  </span>
                ))}
              </li>
            }
          </ul>
      </div>
    </li>
  )
}
