import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import Gallery from '@/components/Gallery'

export default function PracticeRow({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-12 mobile:my-10"
      {...storyblokEditable(blok)}
    >
      <div className="w-full flex mobile:flex-col">
        <div className="xl:w-1/6 md:w-1/4 px-2.5 mr-4 flex items-center justify-center border-l border-slate border-r bg-offwhite mobile:w-full mobile:border-l-0 mobile:border-r-0 mobile:border-b mobile:mb-4 mobile:pb-1 mobile:justify-start mobile:px-0">
          <h2 className="text-md uppercase text-center mobile:text-left mobile:text-m-base tablet:text-base">{blok.title}</h2>
        </div>
        <div className="w-5/6 flex flex-wrap overflow-hidden mobile:w-full">
          <Gallery blok={blok} />
        </div>
      </div>
    </section>
  )
}

