import { storyblokEditable } from "@storyblok/react"
import { StoryblokData } from "@/services/types"

import Button from "@/components/base/Button"

export default function ButtonLink({ blok }: StoryblokData) {
  const url = blok.link.linktype === "url" ? blok.link.url : `/${blok.link.cached_url}`

  const classes = blok.size === 'large' ? 'text-lg px-10 !pt-2.5 !pb-2 !rounded-[50px] mobile:text-m-lg mobile:w-full mobile:text-center' : (blok.size === 'medium' ? 'text-md mobile:text-m-md mobile:w-full mobile:text-center' : 'text-base mobile:text-m-base mobile:w-full mobile:text-center')

  return (
    <section
      key={blok._uid}
      className="w-full flex justify-center px-2.5 py-1"
      {...storyblokEditable(blok)}
    >
      <Button
        text={blok.text}
        color={blok.color}
        link={true}
        linkUrl={url}
        size={blok.size}
        classes={classes}
      />
    </section>
  )
}
