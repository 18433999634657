import { StoryblokComponent } from "@storyblok/react"

import { useFooter } from '../services/utils'

export default function AppFooter() {
  const { footer } = useFooter()

  return (
    <>
      {footer && footer.story && <StoryblokComponent blok={footer.story.content} />}
    </>
  )
}
