import { storyblokEditable } from "@storyblok/react"
import { useSelector } from 'react-redux'
import classNames from "classnames"

import { StoryblokData } from "../services/types"
import ImageBlock from "./ImageBlock"
import Button from "./base/Button"
import Tag from "./base/Tag"

import { listStyle } from '@/services/utils'

export default function HeroPractice({ blok }: StoryblokData) {
  const mobile = useSelector((state: any) => state.app.mobile)

  return (
    <>
    <section
      key={blok._uid}
      className="w-full px-2.5 py-1 mobile:px-0"
      {...storyblokEditable(blok)}
    >
      <div className="w-full relative mobile:flex mobile:flex-col">
        <div
          className={
            classNames(
              "absolute left-2.5 px-2.5 top-[50%] z-[1] transform -translate-y-1/2 flex items-baseline mobile:relative mobile:text-slate mobile:left-[unset] mobile:top-[unset] mobile:translate-y-0 mobile:mb-1 mobile:px-2.5",
              blok.style === 'light' ? 'text-white' : 'text-slate'
            )
          }
        >
          <h1 className="text-lg mr-1 mobile:text-md">{blok.practice.name}</h1>
          {blok.practice?.content.tag && <Tag uuid={blok.practice.content.tag} style="small" />}
        </div>
        {mobile ? ( 
          <div>
            {blok.practice.content.mobileThumbnail && blok.practice.content.mobileThumbnail.filename !== '' ? (
                <ImageBlock
                  image={blok.practice.content.mobileThumbnail}
                  cover={true}
                />
              ) : (
              blok.practice.content.thumbnail && (
                <ImageBlock
                  image={blok.practice.content.thumbnail}
                  hero={true}
                />
              )
            )}
          </div>
         ) : ( 
          <div>
            {blok.practice.content.thumbnail && (
              <ImageBlock
                image={blok.practice.content.thumbnail}
                hero={true}
                single={true}
              />
            )}
          </div>
        )}
        {blok.link &&
          <div className={classNames("text-md absolute right-2.5 px-2.5 top-[50%] transform -translate-y-1/2 mobile:top-[unset] mobile:w-full mobile:flex mobile:justify-center mobile:bottom-2.5 mobile:right-[0%] mobile:translate-x-[0%] mobile:translate-y-0", blok.style === 'light' ? 'text-white' : 'text-slate')}>
            <Button
              link={true}
              linkUrl={blok.link.linktype === 'story' ? blok.link.cached_url : blok.link.url}
              color={blok.style === 'light' ? 'light' : 'dark'}
              text={blok.linkText}
              size="small"
              style={blok.style}
              classes="text-center bg-[rgba(255,255,255,0)] px-16 text-white border-white hover:border-slate hover:text-slate hover:bg-[rgba(255,255,255,0)] mobile:text-m-base mobile:w-full"
            />
          </div>
        }
      </div>
    </section>
    <div className="w-full flex px-2.5 py-1 items-baseline mobile:bottom-[-24px] mobile:px-2.5 mobile:py-0">
      <p className="text-md mobile:text-m-base text-slate mr-2 whitespace-nowrap mobile:text-slate">{blok.practice.content.duration}</p>
      <ul className="flex mx-2 overflow-hidden mobile:mx-0">
        {blok.practice.content.style && blok.practice.content.style.length > 0 &&
          <li
            className={listStyle + ' mobile-up:after:text-grey'}
          >
            {blok.practice.content.style.map((tag: any, index: number) => (
              <span key={tag} className="flex">
                <Tag uuid={tag} style="inline" color="grey" />
                {blok.practice.content.style.length > 1 && index < blok.practice.content.style.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
              </span>
            ))}
          </li>
        }
        {blok.practice.content.tone && blok.practice.content.tone.length > 0 &&
          <li
            className={listStyle + ' mobile-up:after:text-grey'}
          >
            {blok.practice.content.tone.map((tag: any, index: number) => (
              <span key={tag} className="flex">
                <Tag uuid={tag} style="inline" color="grey" />
                {blok.practice.content.tone.length > 1 && index < blok.practice.content.tone.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
              </span>
            ))}
          </li>
        }
        {blok.practice.content.body && blok.practice.content.body.length > 0 &&
          <li
            className={listStyle + ' mobile-up:after:text-grey'}
          >
            {blok.practice.content.body.map((tag: any, index: number) => (
              <span key={tag} className="flex">
                <Tag uuid={tag} style="inline" color="grey" />
                {blok.practice.content.body.length > 1 && index < blok.practice.content.body.length - 1 && <span className="mr-1.5 text-grey text-base mobile:text-m-base mobile:mr-1">,</span>}
              </span>
            ))}
          </li>
        }
        {blok.practice.content.additionalTags && blok.practice.content.additionalTags.map((tag: any) => (
          <li
            key={tag}
            className={listStyle + ' mobile-up:after:text-grey mobile:hidden'}
          >
            <Tag uuid={tag} style="inline" color="grey" />
          </li>
        ))}
      </ul>
    </div>
  </>
  )
}
