import { useState, useEffect } from 'react'
import { storyblokEditable } from "@storyblok/react"
import { ArrowDownIcon, ArrowPathIcon, ArrowSmallDownIcon, ArrowDownTrayIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import { StoryblokData } from "../services/types"

import PracticeItem from '@/components/base/PracticeItem'

const MoreButton = ({ loading, handleClick }: any) => {
  return (
    <button
      className={
        classNames({
          'rounded bg-cream border border-slate flex justify-between text-slate px-1 py-0.5 h-[22px] min-w-[144px] mobile:min-w-[unset] mobile:w-full': true,
        })
      }
      onClick={handleClick}
    >
      <span className="uppercase text-base mobile:text-m-base">{loading ? 'Loading' : 'Show More'}</span>
      <span>
        {loading ? (<ArrowPathIcon className="w-4 h-4 animate-spin" />) : (<ArrowSmallDownIcon className="w-4 h-4" />)}
      </span>
    </button>
  )
}

export default function FilterResults({ blok }: StoryblokData) {
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [results, setResults] = useState<object[]>([])
  // pagination
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const perPage = 24

  const fetchResults = async (page: number) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ page: page, perPage: perPage }),
    }

    const response = await fetch('/api/practice', options)
    
    return await response.json()
  }

  useEffect(() => {
    if (more) {
      const fetchMore = async () => {
        setLoading(true)
        const data = await fetchResults(page)

        // no more to fetch disable button and fetching
        if (data.length === 0 || data.length < perPage) {
          setMore(false)
          setLoading(false)
          return
        }

        setResults(results.concat(data))

        setTimeout(() => {
          // set initial loaded 
          if (!loaded) {
            setLoaded(true)
          }

          // updates per request / update
          setLoading(false)
        }, 100)
      }

      fetchMore()
    }
  }, [page])

  const handleClick = () => {
    setPage(page + 1)
  }

  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 mobile:px-1"
      {...storyblokEditable(blok)}
    >
      {loading && <p className="absolute">Loading...</p>}
      {loaded && results && results.length > 0 && (
        <>
          <ul className="flex flex-wrap">
            {results.map((practice: any) => (
              <PracticeItem
                key={practice.id}
                practice={practice}
                width="25"
                classes="mb-16"
              />
            ))}
          </ul>
        </>
      )}
      {more && loaded && (
        <div className="flex justify-center mt-6 mb-10">
          <MoreButton loading={loading} handleClick={handleClick} />
        </div>
      )}
      {!loading && results && results.length === 0 && (
        <p>No results found.</p>
      )}
    </section>
  )
}
