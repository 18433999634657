import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'

import { errorClasses, formErrorClasses } from '@/services/utils'

import Button from '@/components/base/Button'

interface SignInOTPValues {
  otp: string,
}

type OtherProps = {
  userAction: any,
  serverErrors?: any,
}

const BaseForm = (props: OtherProps & FormikProps<SignInOTPValues>) => {
  const { touched, errors, isSubmitting, serverErrors } = props

  const fieldClasses = 'form-input text-base w-full mb-2.5 pt-2 tracking-[3.5px] pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'

  return (
    <Form
      className={
        classNames({
          'flex flex-col': true,
          'opacity-40': isSubmitting,
        })
      }
    >
      <div className="w-full">
        {serverErrors && serverErrors.length > 0 && (
          <div className={formErrorClasses}>There was an issue with your pass code: <span className={errorClasses + ' !ml-1'}>{serverErrors.join(', ')}</span></div>
        )}
      </div>
      <div className="w-full">
        {touched.otp && errors.otp && (<p className={errorClasses}>{errors.otp}</p>)}
        <Field name="otp" type="text" placeholder="______" className={fieldClasses} />
      </div>
      <div className="w-full flex justify-between items-end mobile:flex-col mobile:justify-start mobile:items-start">
        <Button
          text="Verify"
          size="small"
          classes="text-center w-fit px-12 min-w-[160px] mobile:w-full"
          color="dark"
          disabled={isSubmitting}
        />
        {/*
        {isSubmitting ? (
            <p className="text-sm uppercase mb-1 text-slate">Loading...</p>
          ) : (
            <p className="text-base mb-1 mobile:text-m-base mobile:mt-3 mobile:pl-2">
              <Link href="/account/reset-password" className="text-slate">Forgetten password?</Link>
            </p>
          )}
        */}
      </div>
    </Form>
  )
}

interface OTPFormProps {
  errors?: string,
  handleSubmit: any,
  serverErrors?: any,
}

const PasscodeForm = withFormik<OTPFormProps, SignInOTPValues>({
  mapPropsToValues: () => ({
    otp: '',
  }),
  validationSchema: Yup.object({
    otp: Yup.string().required('Passcode is Required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)

    console.log('otp form values', values)

    props.handleSubmit(values)
  }
})(BaseForm)

export default function SignInOTPToken(props: any) {
  return (
    <div className="">
      <PasscodeForm
        handleSubmit={props.userAction}
        serverErrors={props.serverErrors}
      />
    </div>
  )
}
