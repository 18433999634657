import { StoryblokComponent } from "@storyblok/react"

import { useFilter } from '../services/utils'

export default function AppFilter() {
  const { filter } = useFilter()

  return (
    <>
      {filter && filter.story && <StoryblokComponent blok={filter.story.content} rels={filter.rels} />}
    </>
  )
}
