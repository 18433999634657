import { useState } from 'react'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { EnvelopeIcon } from '@heroicons/react/24/outline'

import classNames from 'classnames'

import Button from '@/components/base/Button'

interface ResetValues {
  email: string,
}

type OtherProps = {
  userAction: any,
  serverErrors?: any,
  loading: boolean,
}


const ResetStep = (props: any) => {
  const { title, step, loaded, children } = props

  return (
    <div className={classNames('flex flex-col w-full opacity-0 transition-opacity duration-300 delay-200', {
      'hidden': step !== props.step,
      'opacity-100': loaded
    })}>
      <header className="my-2.5 relative">
        <h5 className="text-base text-center mobile:text-m-base">{title}</h5>
      </header>

      {children}
    </div>
  )
}

const BaseForm = (props: OtherProps & FormikProps<ResetValues>) => {
  const { touched, errors, isSubmitting, serverErrors, loading } = props

  const fieldClasses = 'form-input text-base w-full mb-2.5 pt-2 pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'
  const errorClasses = 'text-xs uppercase mb-1 text-slate tracking-wider mb-1.5 ml-3'

  return (
    <Form
      className={
        classNames({
          'flex flex-col': true,
          'opacity-40': isSubmitting,
        })
      }
    >
      <div className="w-full">
        {serverErrors && serverErrors.length > 0 && (
          <div className="text-sm text-grey !mt-2.5 mb-1.5 pl-3">There was an issue with your sign in: <span className={errorClasses + ' !ml-1'}>{serverErrors.join(', ')}</span></div>
        )}
      </div>
      <div className="w-full">
        {touched.email && errors.email && (<p className={errorClasses}>{errors.email}</p>)}
        <Field name="email" type="email" placeholder="Email" className={fieldClasses} />
      </div>
      <div className="w-full flex justify-between items-end">
        <Button
          text="Reset Password"
          size="small"
          classes="text-center w-fit px-12 min-w-[160px] mobile:w-full"
          color="dark"
          disabled={isSubmitting || loading}
        />
      </div>
    </Form>
  )
}

interface ResetFormProps {
  errors?: string,
  handleSubmit: any,
  loading: boolean,
  serverErrors?: any,
}

const ResetForm = withFormik<ResetFormProps, ResetValues>({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true)

    props.handleSubmit(values)
  }
})(BaseForm)

export default function ResetPassword(props: any) {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const [formLoaded, setFormLoaded] = useState(true)
  const [resetSentLoaded, setResetSentLoaded] = useState(false)

  const supabase = useSupabaseClient()

  const handlePasswordReset = async (values: { email: string }) => {
    setLoading(true)

    const { data } = await supabase.auth.resetPasswordForEmail(values.email, { redirectTo: 'https://rise-platform.vercel.app/account/new-password' })

    if (data) {
      setStep(2)
      setFormLoaded(false)
      setResetSentLoaded(true)
    }
  }

  return (
    <div className="w-[66%] max-w-lg mobile:w-full mobile:max-w-[unset] tablet:w-full tablet:max-w-none">
      <ResetStep title="Request Password Reset" step={1} loaded={formLoaded}>
        <ResetForm
          handleSubmit={handlePasswordReset}
          serverErrors={props.serverErrors}
          loading={loading}
        />
      </ResetStep>
      <ResetStep title="" step={2} loaded={resetSentLoaded}>
        <div className="text-center flex flex-col items-center">
          <p className="mb-6 text-slate text-base">We have sent you an email to reset your password.</p>
          <EnvelopeIcon className="w-6 h-6 text-slate stroke-1" />
        </div>
      </ResetStep>
    </div>
  )
}
