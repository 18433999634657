import { useState, useEffect } from 'react'
import { storyblokEditable } from "@storyblok/react"
import { useUser } from '@supabase/auth-helpers-react'

import { StoryblokData } from "../services/types"

import PracticeItem from '@/components/base/PracticeItem'

export default function UserPractice({ blok }: StoryblokData) {
  const [favourites, setFavourites] = useState([])
  const [practices, setPractices] = useState([])

  const [loading, setLoading] = useState(true)

  const user = useUser()

  useEffect(() => {
    const getFavourites = async () => {
      const response = await fetch(`/api/supabase/favourites/${user?.id}`) 
      const data = await response.json()
      setFavourites(data)
      setLoading(false)
    }

    if (user) {
      getFavourites() 
    }
  }, [user])

  useEffect(() => {
    if (!favourites || favourites.length === 0) {
      return 
    }

    const getPractices = async () => {
      const ids = favourites.map((favourite: any) => favourite.practice_uuid)

      const options = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ ids: [ ...ids ] })
      }

      const response = await fetch(`/api/practice`, options)
      const data = await response.json()
      setPractices(data)
      setLoading(false)
    }

    getPractices()
  }, [favourites])

  return (
    <section
      key={blok._uid}
      className="w-full px-1.5 py-1 my-8 mobile:px-2.5 mobile:my-0 mobile:py-0"
      {...storyblokEditable(blok)}
    >
      <ul className="flex flex-wrap">
        <li className="w-1/4 h-auto mb-16 flex relative px-1.5 mobile:w-full mobile:mb-4 mobile:px-0">
          <div className="w-full !aspect-w-16 !aspect-h-9 mobile:!aspect-none">
            <div className="w-full px-3 border-l border-l-slate border-r border-r-slate flex flex-col justify-center items-center mobile:w-full mobile:items-start mobile:border-l-0 mobile:border-r-0 mobile:border-b mobile:mb-0 mobile:pb-1 mobile:justify-start mobile:px-0">
              <h3 className="text-md uppercase mobile:text-m-base">My Favourites</h3>
            </div>
          </div>
        </li>
        {!loading && practices && practices.length > 0 && (
          practices.map((practice: any) => (
            <PracticeItem
              key={practice.id}
              practice={practice}
              width="25"
              classes="mb-16 mobile:px-0 mobile:mb-6"
            />
          ))
        )}
    </ul>
    </section>
  )
}
