import { useState } from 'react'
import { storyblokEditable, StoryblokComponent } from "@storyblok/react"

import { StoryblokData } from "@/services/types"

import HeadingListContent from "@/components/HeadingListContent"

export default function HeadingList({ blok }: StoryblokData) {
  const [active, setActive] = useState(null)

  return (
    <section
      key={blok._uid}
      className="w-full px-3 py-1 my-8 flex mobile:px-2.5 mobile:flex-col mobile:relative mobile:my-5"
      {...storyblokEditable(blok)}
    >
      <div className="w-1/2 h-full mobile:w-full mobile:h-auto mobile:flex mobile:flex-col mobile:min-h-fit mobile:z-10">
        {blok.items && blok.items.length > 0 &&
          blok.items.map((item: StoryblokData) => (
            <StoryblokComponent
             key={item._uid}
             blok={item}
             total={blok.items.length}
             setActive={setActive}
             active={active}
            />
          ))
        }
      </div>
      <div className="w-1/2 h-auto relative mobile:hidden">
        {blok.items && blok.items.length > 0 &&
          blok.items.map((item: StoryblokData) => (
            <HeadingListContent
              key={item._uid}
              description={item.description}
              image={item.image}
              group={item.group}
              align={item.align}
              width={item.width}
              show={active === item._uid}
            />
          ))
        }
      </div>
    </section>
  )
}
