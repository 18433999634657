import { useState, useEffect } from 'react'
import { withFormik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import classNames from 'classnames'

import { errorClasses, verifyAuthEmail } from '@/services/utils'

import Button from '@/components/base/Button'

interface CreateAccountWithEmailValues {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
}

type OtherProps = {
  userAction: any
}

const BaseForm = (props: OtherProps & FormikProps<CreateAccountWithEmailValues>) => {
  const { touched, errors, isSubmitting } = props

  const fieldClasses = 'form-input w-full mb-2.5 text-base pt-2 pb-1.5 px-3 mx-0 rounded-[20px] border border-slate bg-offwhite'

  return (
      <Form
        className={
          classNames({
            'flex flex-col': true,
            'opacity-40': isSubmitting,
          })
        }
      >
        <div className="w-full">
          {touched.firstName && errors.firstName && (<p className={errorClasses}>{errors.firstName}</p>)}
          <Field name="firstName" type="text" placeholder="First Name" className={fieldClasses} />
        </div>
        <div>
          {touched.lastName && errors.lastName && (<p className={errorClasses}>{errors.lastName}</p>)}
          <Field name="lastName" type="text" placeholder="Last Name" className={fieldClasses} />
        </div>
        <div>
          {touched.email && errors.email && (<p className={errorClasses}>{errors.email}</p>)}
          <Field name="email" type="email" placeholder="Email" className={fieldClasses} />
        </div>
        <div>
          {touched.password && errors.password && (<p className={errorClasses}>{errors.password}</p>)}
          <Field name="password" type="password" placeholder="Password" className={fieldClasses} />
        </div>

        <Button
          text="Create Account"
          size="small"
          color="dark"
          classes="w-fit"
        />
      </Form>
  )
}

interface EmailFormProps {
  [key: string]: any,
  handleSubmit: any,
  emailUnique: any,
  emailError: any,
}

const CreateAccountWithEmailForm = withFormik<EmailFormProps, CreateAccountWithEmailValues>({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }),
  enableReinitialize: true,
  validationSchema: Yup.object({
    firstName: Yup.string().required('First name is Required'),
    lastName: Yup.string().required('Last name is Required'),
    email: Yup.string().email('Invalid email address').required('Email is Required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is Required'),
  }),
  handleSubmit: async (values: any, { props, setSubmitting, setTouched, setFieldValue, setFieldError }) => {
    setSubmitting(true) 

    const { email } = await verifyAuthEmail(values.email)

    // const { list } = await verifyCustomerEmail(values.email)

    if (email) {
      setTouched({ 'email': true }, false)
      setFieldValue('email', values.email, false)
      setFieldError('email', 'Email already exists')

      props.emailUnique(false)
      props.emailError(true)
      
      return
    }

    props.handleSubmit(values)
  }
})(BaseForm)

export default function CreateAccountWithEmail(props: any) {
  return (
    <>
      <CreateAccountWithEmailForm
        emailUnique={props.userAction.unique}
        emailError={props.userAction.emailError}
        handleSubmit={props.userAction.submit}
      />
    </>
  )
}
