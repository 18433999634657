import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'

import { getUserSubscriptions, cancelUserSubscriptions } from '@/services/utils'

import Popup from '@/components/Popup'
import Button from '@/components/base/Button'
import ProfilePersonal from '@/components/forms/ProfilePersonal'
import ProfileSubscription from '@/components/forms/ProfileSubscription'
import ProfilePayment from '@/components/forms/ProfilePayment'
import SubscriptionItem from '@/components/forms/SubscriptionItem'
import CancelSubscription from '@/components/forms/CancelSubscription'

export default function UserProfile() {
  const supabaseClient = useSupabaseClient()
  const user = useUser()

  const [subscription, setSubscription] = useState<null | any>(null)
  const [customer, setCustomer] = useState<null | any>(null)
  const [card, setCard] = useState<null | any>(null)
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true)
  const [loadingCard, setLoadingCard] = useState(true)

  const [showCancel, setShowCancel] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [cancelledMessage, setCancelledMessage] = useState('')

  // chargebee
  const [cbInstance, setCbInstance] = useState<any>(null)

  const router = useRouter()

  // set up chargebee
  useLayoutEffect(() => {
    if (!(window as any).Chargebee) return
    
    const instance = (window as any).Chargebee.init({
      site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
      isItemsModel: true,
    })
    setCbInstance(instance)
  }, [])

  // set portal session
  useEffect(() => {
    if (!cbInstance || !customer) return
 
    cbInstance.setPortalSession(async () => {
      const url = `/api/chargebee/portal/create`

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: customer?.id }),
      })

      return await response.json()
    })
  }, [cbInstance, customer])

  useEffect(() => {
    if (!user) {
      return
    }

    const fetchSubscriptions = async () => {
      setLoadingSubscriptions(true)

      const data = await getUserSubscriptions(user.id)

      // filter items
      const subscriptionItems = data.map((item: any) => item.subscription)
      const customerItems = data.map((item: any) => item.customer)
      const cardItems = data.map((item: any) => item.card)

      // set subscription, customer and card
      setSubscription(subscriptionItems[0])
      setCustomer(customerItems[0])
      setCard(cardItems[0])

      // turn off loading
      setLoadingSubscriptions(false)
      setLoadingCard(false)
    }

    fetchSubscriptions()
  }, [user])

  const handleManageSubscription = async () => {
    if (subscription && customer) {
      const cbPortal = cbInstance && cbInstance.createChargebeePortal()
      
      cbPortal.openSection({
        sectionType: (window as any).Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
        params: {
          subscriptionId: subscription.id,
        }
      })
    }
  }

  const handlePaymentDetails = async () => {
    if (subscription && customer && card) {
      const cbPortal = cbInstance && cbInstance.createChargebeePortal()
      
      cbPortal.openSection({
        sectionType: (window as any).Chargebee.getPortalSections().PAYMENT_SOURCES,
      })
    }
  }

  const handleBillingHistory = async () => {
    if (subscription && customer && card) {
      const cbPortal = cbInstance && cbInstance.createChargebeePortal()

      cbPortal.openSection({
        sectionType: (window as any).Chargebee.getPortalSections().BILLING_HISTORY,
      })
    }
  }

  const handleCancel = async () => {
    if (subscription && subscription?.id) {
      const data = await cancelUserSubscriptions(subscription.id)

      const endsAt = data.subscription.status === 'in_trial' ? new Date(data.subscription.trial_end * 1000) : new Date(data.subscription.current_term_end * 1000)

      const message = `${data.subscription.status === 'in_trial' ? 'Your subscription has been cancelled, your trial will end ' : 'Your subscription has been cancelled, you will have access until'} ${new Intl.DateTimeFormat("en-AU", { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' }).format(endsAt)}` 
      
      setCancelled(true) 
      setCancelledMessage(message)
    }
  }

  const signOut = async () => {
    await supabaseClient.auth.signOut()

    router.push('/')
  }

  return (
    <section className="w-full mt-[100px]">
      {user ? (
        <>
          <div className="max-w-[640px] mx-auto mb-20 mobile:w-full mobile:px-2.5">
            <Button
              text="Sign Out"
              color="dark"
              clickEvent={signOut}
              size="regular"
              classes="!w-full text-center"
            />
          </div>
          <div className="max-w-[640px] mx-auto mb-20 mobile:w-full mobile:px-2.5">
            <h5 className="text-base uppercase mb-2.5 text-center">Personal information</h5>
            <ProfilePersonal 
              firstName={user.user_metadata.first_name}
              lastName={user.user_metadata.last_name}
              email={user.user_metadata.email}
            />
          </div>
          <div className="max-w-[640px] mx-auto mb-20 mobile:w-full mobile:px-2.5">
            <h5 className="text-base uppercase mb-2.5 text-center">Subscription</h5>
            {subscription && subscription?.id && (
              <div className="mt-3 hidden">
                <SubscriptionItem
                  id={subscription?.subscription_items[0]?.item_price_id}
                  price={subscription?.subscription_items[0]?.unit_price}
                  name={subscription?.subscription_items[0]?.item_price_name}
                  active={true}
                  type="button"
                />
              </div>
            )}

            {loadingSubscriptions ? (
              <p className="text-sm uppercase">Loading...</p>
            ) : (
              <ProfileSubscription item={subscription} />
            )}
            {subscription && subscription?.id && (
              <div className="mb-3">
                <Button
                  text="Manage Subscription"
                  color="light"
                  clickEvent={() => handleManageSubscription()}
                  size="regular"
                  classes="!w-full text-center"
                />
              </div>
            )}
            {subscription && subscription?.id && (
              <div className="flex justify-end hidden">
                <button
                  onClick={() => setShowCancel(true)}
                  className="text-sm uppercase"
                >
                  Cancel Subscription
                </button>
              </div>
            )}
          </div>
          <div className="max-w-[640px] mx-auto mb-20 mobile:w-full mobile:px-2.5">
            <h5 className="text-base uppercase mb-2.5 text-center">Payment information</h5>
            {loadingCard && (
              <p className="text-sm uppercase">Loading...</p>
            )}
            {!loadingCard && card && (
              <ProfilePayment 
                item={card}
              />
            )}
            {!loadingCard && card && (
              <>
                <div className="mb-3">
                  <Button
                    text="Manage Payment Details"
                    color="light"
                    clickEvent={() => handlePaymentDetails()}
                    size="regular"
                    classes="!w-full text-center"
                  />
                </div>
                <div className="mb-3">
                  <Button
                    text="View Billing History"
                    color="dark"
                    clickEvent={() => handleBillingHistory()}
                    size="regular"
                    classes="!w-full text-center"
                  />
                </div>
              </>
            )}
          </div>
          <Popup
            active={showCancel}
            close={() => setShowCancel(false)}
            style="dark"
            width="medium"
          >
            <CancelSubscription
              success={cancelled}
              message={cancelledMessage}
              userAction={handleCancel}
            />
          </Popup>
        </>
      ) : (
        <div className="max-w-[640px] mx-auto mb-20 mobile:w-full mobile:px-2.5">
          <p className="text-base text-center">You are not signed in.</p>
        </div>
      )}
    </section>
  )
}
