import { useState, useEffect } from 'react'
import  { useRouter } from 'next/router'
import { useUser } from '@supabase/auth-helpers-react'
import { useSelector, useDispatch } from 'react-redux'
import { setFilterShow, setFilters, setFilterResults } from '../services/state'
import { storyblokEditable } from "@storyblok/react"
import { XMarkIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import classNames from "classnames"

import { StoryblokData } from "@/services/types"

import FilterTag from '@/components/FilterTag'

export default function FiltersBlock({ blok, rels }: StoryblokData) {
  const [rowCategories, setRowCategories] = useState<[] | null>(null)
  const [active, setActive] = useState<string[]>([])

  const dispatch = useDispatch()
  const router = useRouter()

  const mobile = useSelector((state: any) => state.app.mobile)
  const tablet = useSelector((state: any) => state.app.tablet)
  const show = useSelector((state: any) => state.filter.show)
  const results = useSelector((state: any) => state.filter.results)
  const filters = useSelector((state: any) => state.filter.filters)

  const user = useUser()

  useEffect(() => {
    if (router.query.tags) {
      const tags = router.query.tags.includes(',') ? (router.query.tags as string).split(',') : [router.query.tags]

      dispatch(setFilters(tags))
    }
  }, [router.query])

  useEffect(() => {
    if (filters.length > 0) {
      const fetchResults = async () => {
        const response = await fetch(`/api/practice/filter?tags=${filters}`)

        const data = await response.json()

        dispatch(setFilterResults(data))
      }

      // fetchResults()
    } else {
      dispatch(setFilterResults([]))
    }
  }, [filters])

  useEffect(() => {
    if (blok.categories) {
      const categories = blok.categories.map((id: string) => {
        const relCategory = rels.find((rel: any) => rel.uuid === id)
        relCategory.active = false

        return relCategory
      })

      setRowCategories(categories)
    }
  }, [blok.categories])

  const handleFilterClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()

    const slug = evt.currentTarget.dataset.slug

    const active = filters.findIndex((item: string) => item === slug)

    if (active === -1) {
      dispatch(setFilters([...filters, slug]))
    } else {
      dispatch(setFilters(filters.filter((item: string) => item !== slug)))
    }
  }

  const handleFilter = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()

    const slug = `/practice/filters?tags=${filters.join(',')}`

    router.push(slug)

    setTimeout(() => {
      dispatch(setFilterShow(false))
    }, 1200)
  }

  const toggleCategory = (category: any) => {
    const catActive = active.findIndex((item: string) => item === category.slug)

    if (catActive === -1) {
      setActive([...active, category.slug])
    } else {
      setActive(active.filter((item: string) => item !== category.slug))
    }
  }

  return (
    <>
    {user && (
      <section
        key={blok._uid}
        className={
          classNames({
            'w-full flex flex-col justify-between px-3 z-50 pt-10 pb-3 fixed top-[120vh] left-0 h-[100vh] bg-offwhite transition-all duration-[800ms] shadow-[0px_-15px_50px_50px_rgba(249,_248,_245,_1)] mobile:px-0 mobile:duration-500 mobile:justify-start': true,
            '!top-0': show,
          })
        }
        {...storyblokEditable(blok)}
      >
        <div className="flex justify-between ml-[-20px] mobile:flex-wrap mobile:overflow-y-auto mobile:px-1.5 mobile:ml-0 mobile:w-full tablet:w-full tablet:flex-wrap tablet:ml-0 tablet:overflow-y-auto">
          {rowCategories && rowCategories.map((item: any) => (
            <div
              key={item.id}
              className="w-1/12 grow px-5 mobile:w-full mobile:mb-4 mobile:px-0 tablet:w-full tablet:mb-2.5 tablet:px-0"
            >
              <header className="flex w-full items-center px-1 justify-between mb-2 mobile:mb-1">
                <button
                  className="uppercase text-base mt-2.5 flex justify-between w-full mobile:text-m-base mobile:mx-1 mobile:border-b mobile:border-b-grey mobile:pb-0.5 mobile:mb-2 tablet:mx-1 tablet:border-b tablet:border-b-grey tablet:pb-2.5 tablet:mb-1"
                  onClick={() => toggleCategory(item)}
                >
                  {item.name}
                  <span className="mobile-up:hidden tablet:block">
                    {active.includes(item.slug) ? (
                      <ChevronUpIcon className="w-4 h-4" />
                    ) : (
                      <ChevronDownIcon className="w-4 h-4" />
                    ) }
                  </span>
                </button>
              </header>
              <div
                className={
                  classNames({
                    'w-full mobile:hidden tablet:hidden': true,
                    '!block': (mobile || tablet) && active.includes(item.slug),
                  })
                }
              >
              {item.content.tags &&
                <ul className="mobile:flex mobile:flex-wrap tablet:flex tablet:flex-wrap">
                  {item.content.tags.map((tag: any) => (
                    <li
                      key={tag}
                      className="mb-2.5 last:mb-0 mobile:w-1/2 mobile:my-1 px-1 tablet:w-1/2 tablet:my-1 tablet:px-1"
                    >
                      <FilterTag
                        uuid={tag}
                        active={filters}
                        handleClick={handleFilterClick}
                      />
                    </li>
                  ))}
                </ul>
              }
              </div>
            </div>
          ))}
          <div className="absolute right-4 top-2 mobile:right-2.5">
            <button
              onClick={() => dispatch(setFilterShow(false))}
              className="w-8 h-8 flex items-center justify-center text-slate mobile:h-6 mobile:w-6"
            >
              <XMarkIcon className="w-6 h-6 mobile:w-5 mobile:h-5" />
            </button>
          </div>
        </div>
        <div 
          className={
            classNames({
              // 'mobile:px-2.5 mobile:absolute mobile:top-[calc((var(--vh)*90)-12px)] mobile:w-full': true,
              'mobile:px-2.5': true,
            })
          }
        >
          <button
            className={classNames({
              'w-full relative pt-1.5 pb-1 text-lg leading-[0.9] uppercase rounded-full bg-slate text-white flex items-start justify-center mobile:text-m-lg': true,
            })}
            onClick={handleFilter}
          >
            <span>Explore Practices</span>
            {results && results.length > 0 && <span className="uppercase text-lg inline-block ml-1 right-5 top-[50%] translate-y-[-50%] absolute tracking-wider">{results.length}</span>}
          </button>
        </div>
      </section>
    )}
    </>
  )
}
