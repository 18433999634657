import { StoryblokComponent } from "@storyblok/react"

import { useHeader } from '../services/utils'

export default function AppHeader() {
  const { header } = useHeader()

  return (
    <>
      {header && header.story && <StoryblokComponent blok={header.story.content} />}
    </>
  )
}
