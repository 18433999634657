import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import Button from './base/Button'

export default function TopicsRow({ blok }: StoryblokData) {
  return (
    <section
      key={blok._uid}
      className="w-full px-3 my-4 flex justify-between items-baseline mobile:flex-wrap mobile:px-2.5 mobile:my-2.5 mobile:hidden"
      {...storyblokEditable(blok)}
    >
      <h3 className="text-base uppercase mobile:text-m-base mobile:text-center mobile:w-full mobile:mb-2.5">{blok.title}</h3>

      <ul className="flex flex-wrap items-baseline justify-center">
        {blok.topics && blok.topics.map((topic: any) => (
          <li
            key={topic.id}
            className="mx-1.5 my-2.5 mobile:mx-0.5 mobile:my-0.5"
          >
            <Button
              text={topic.name}
              color="light"
              size="small"
              link={true}
              linkUrl={`/blog?topic=${topic.slug}`}
              classes="min-w-[150px] py-0 text-center hover:!text-slate hover:!bg-transparent hover:!cursor-default mobile:text-m-base mobile:min-w-[unset]"
            />
          </li>
        ))}
      </ul>
    </section>
  )
}
