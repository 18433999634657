import { storyblokEditable } from "@storyblok/react"

import { StoryblokData } from "../services/types"

import classNames from "classnames"

export default function ContentBreak({ blok }: StoryblokData) {
  return (
    <aside
      key={blok._uid}
      className={
        classNames({
          'w-full px-3 py-1': true,
          'py-2.5 mobile:py-1.5': blok.size === 'xsmall',
          'py-5 mobile:py-3': blok.size === 'small',
          'py-8 mobile:py-5': blok.size === 'medium',
          'py-12 mobile:py-8': blok.size === 'large',
          'py-20 mobile:py-10': blok.size === 'xlarge',
        })
      }
      {...storyblokEditable(blok)}
    >
    </aside>
  )
}
